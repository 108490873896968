/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import {RouteComponentProps} from 'react-router-dom';
import {useFormik, FormikHelpers} from 'formik';
import parseDate from 'date-fns/parse';
import ptBR from 'date-fns/locale/pt-BR';
import * as Validate from 'validations-br';
import useFetch from '../../../hooks/useFetch';
import { IField } from '../../../components/ListTable';
import { FormWrapper, Title, FormContent, Form, Button } from '../styles';
import {orderCols, adjustByRow, treatColField, formatCnpj, formatCpf} from '../../../utils/form';
import {isValidDate} from '../../../utils/date';

import {HeaderContent, ActionsContainer} from '../styles';

import FormInputs from '../../../components/FormInputs';
interface Props extends RouteComponentProps {
  title: string;
  endpoint: string;
  customFields?: any;
  editCustomFields?: any;
  filters: any;
  actions?: any;
  path?: any;
}

const FormTemplateRequest: React.FC<Props> = ({
  title,
  endpoint,
  path,
  history,
  actions,
  location,
  filters,
}) => {
  const colsHandler = useFetch({
    endpoint: `${endpoint}/campos/`,
  });
  const [pinedClass, setPinedClass] = useState(false);
  const [cols, setCols] = useState<Array<IField>>([]);
  const [fields, setFields] = useState<any>({});
  const [actionsButtons, setActionsButtons] = useState<any[]>([]);
  const [lookups, setLookups] = useState<any>({});

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {},
    validate,
    validateOnChange: false,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    getData(true);
  }, [endpoint]);

  useEffect(() => {
    if (location && !location.state && filters && Object.keys(fields).length > 0 && !pinedClass) {
      pinClass(filters.classe, false);
      setPinedClass(true);
    }
  }, [location, cols, filters, fields, pinedClass]);

  useEffect(() => {
    if (cols) {
      adjustStartCols();
    }
  }, [cols]);

  useEffect(() => {
    if (actions) {
      const globalActions = actions.filter((action: any) => action.type === "global");

      if (globalActions.length > 0) {
        const global = globalActions.map((action: any) => {
          const option: any = {
            ...action,
            onClick: 
                action.onClick 
                ? typeof action.onClick === 'string' 
                ? () => history.push({
                  pathname: action.onClick,
                  state: action.params ? {
                    ...action.params
                  } : {},
                })
                : () => action.onClick()
                : () => {},
          };
          return option;
        });
  
        setActionsButtons(global);
      }
    }
  }, []);

  function adjustStartCols() {
    const colsField: any = {};
    const orderedCols: any = cols.sort(orderCols);
    
    for (const col of orderedCols) {
      const field = treatColField(col, handleChangeLookup, null);

      if (field) {
        colsField[col.campo] = field;
      }
    }

    let byRow: any = adjustByRow(colsField);
    setFields(byRow);
  }

  async function getData(_firstRender = false) {
    const result = await colsHandler.get(filters);
    const editableCols = result.filter((col: any) => col.editavel === true);
    setCols(editableCols);
  }

  function validate(values: any) {
    const filteredValues = Object.keys(values).filter(key => values[key] !== '' && values[key] !== null);
    const errors: any = {};

    for (const key of filteredValues) {
      let isValid;
      switch (key) {
        case "cpfCnpj":
          if (values[key].replace(/\D/g, "").length < 12) {
            isValid = Validate.validateCPF(values[key]);
            if (!isValid) {
              errors[key] = 'CPF inválido';
            }
           } else {
            isValid = Validate.validateCNPJ(values[key]);
            if (!isValid) {
              errors[key] = 'CNPJ inválido';
            }
          }
          
          break;
        case "cep":
          isValid = Validate.validateCep(values[key]);
          if (!isValid) {
            errors[key] = 'CEP inválido';
          }
          break;
        case "email":
          isValid = Validate.validateEmail(values[key]);
          if (!isValid) {
            errors[key] = 'E-mail inválido';
          }
          break;
        case "telefone":
        case "celular":
          isValid = Validate.validatePhone(values[key]);
          if (!isValid) {
            errors[key] = 'Número inválido';
          }
          break;
        default:
          break;
      }
    }
    return errors;
  }
  
  function handleChangeLookup(field: string, options: any) {
    return setLookups((prevState: any) => {
      return {...prevState, [field]: options}
    });
  }

  function pinClass(colValue?: string, disabled?: boolean, multiple: any | null = null) {
    let byRow = {...fields};
    let keys: string[];
    if (multiple) keys = Object.keys(multiple); 
    for (const key of Object.keys(byRow)) {
      const value = byRow[key];
      let found = false;

      byRow[key] = value.map((formField: any) => {
        if (multiple) {
          if (keys.includes(formField.id)) {
            let initialState = {};
            if (multiple[formField.id]) {
              initialState = { byId: multiple[formField.id] };
            }
            return {
              ...formField,
              disabled,
              initialState,
            };
          }
        } else {
          if (formField.id === 'Classe') {
            found = true;
            let initialState = {};
            
            if (colValue) {
              initialState = { byName: colValue };
            }
            
            return {
              ...formField,
              disabled,
              initialState,
            }
          }
        }

        return formField;
      });
      
      if (found) {
        break;
      }
    }

    setFields(byRow);
  }

  async function handleSubmit(values: any, helpers: FormikHelpers<any>) {
    let payload = {
      ...values,
    };

    if (lookups) {
      const keys = Object.keys(lookups);

      for (const key of keys) {
        if(Array.isArray(lookups[key])){
          payload = {
            ...payload,
            [key]: lookups[key].map((item: any) => item.value)
          }
        } else{
          const idKey = `id${key}`;
          payload = {
            ...payload,
            [idKey]: lookups[key].value,
          }
        }
      }
    }
    
    let adjustedPayload: any = {};
  
    for (const key of Object.keys(payload)) {
      const value = payload[key];
      const isDate = isValidDate(value);
      if (isDate) {
        adjustedPayload = {
          ...adjustedPayload,
          [key]: parseDate(value, isDate === 'date' ? 'dd/MM/yyyy' : 'dd/MM/yyyy HH:mm', new Date(), { locale: ptBR }),
        }
      } else {
        if (key === 'cpfCnpj') {
          if (value === null || value === "") {
            adjustedPayload[key] = null;
          } else {
            let adjustedValue = value.replace(/\D/g, "");
            if (adjustedValue.length < 12) {
              adjustedValue = formatCpf(adjustedValue);
            } else {
              adjustedValue = formatCnpj(adjustedValue);
            }
            adjustedPayload[key] = adjustedValue;
          }
        } else {
          adjustedPayload[key] = value === "" ? null : value;
        }
      }
    }
    
    try {
      history.push({
        pathname: `${path}/resultado`,
        state: {
          filtersToGet: adjustedPayload,
        }
      })
    } catch (err: any) {
      helpers.setSubmitting(false);
    }
  }

  function handleNewRequest(){
    history.push({pathname: `${path}/inserir`})
  }

  return (
    <FormWrapper>
    <HeaderContent>
      <Title>{title}</Title>
      <ActionsContainer>
            <Button
              onClick={() => {}}
            >
              NOVO TÍTULO
            </Button>
      </ActionsContainer>
    </HeaderContent>
      <FormContent>
        <Form onSubmit={form.handleSubmit}>
          <FormInputs fields={fields} form={form} />
          <Button disabled={form.isSubmitting} variant="filled" type="submit">Buscar</Button>
        </Form>
      </FormContent>
    </FormWrapper>
  );
}

export default FormTemplateRequest;