import React, { useEffect } from 'react';
import { 
    Route,
    Switch,
    Redirect,
    RouteComponentProps,
	useLocation,
	useHistory,
} from 'react-router-dom';

import Home from '../screens/Home';
import Users from '../screens/Users';
import Template from '../components/Template';
import FormTemplate from '../components/Template/forms';
import SearchTemplate from '../components/Template/search';
import ReportTemplate from '../components/Template/report';
import ImportTemplate from '../components/Template/import';
import ClassExplorer from '../screens/ClassExplorer';
import Report from '../screens/Report';

import SalesOrder from '../screens/SalesOrder';
import PurchaseServiceOrder from '../screens/PurchaseServiceOrder';
import SellingServiceOrder from '../screens/SellingServiceOrder';
import PurchaseOrder from '../screens/PurchaseOrder';
import Billing from '../screens/Billing';
import LightRelease from '../screens/LightRelease';
import Title from '../screens/Title';
import AccountPlan from '../screens/AccountPlan';
import AccountingEntry from '../screens/AccountingEntry';
import Inventory from '../screens/Inventory';

import { useAuth } from '../contexts/auth';

import {
	AuthRoutes,
	EditRoutes,
	InsertRoutes,
	ListRoutes,
} from './routes';
import {pages} from './pages';
import {data as importData} from './importStructure';
import EspelhoPedido from '../components/EspelhoPedido';
import ReportExtract from '../screens/ReportExtact';
import FichaFuncionario from '../components/FichaFuncionario';
import OrderReport from '../components/OrderReport';
import FormTemplateRequest from '../screens/Requests/search';
import TitleFormTemplateRequest from '../screens/Titles/search';
import NewRequest from '../screens/Requests/NewRequest';
import ProvidersReport from '../screens/ProvidersReport';
import ProductsReport from '../screens/ProductReport';
import PurchaseReport from '../screens/PurchaseReport';
import FormProvidersReportTemplate from '../screens/ProvidersReport/search';
import ConductorsReport from '../screens/ConductorsReport';
import FormConductorsReportTemplate from '../screens/ConductorsReport/search';
import VehicleReport from '../screens/VehicleReport';
import FormVehicleReportTemplate from '../screens/VehicleReport/search';
import TitlesReport from '../screens/TitlesReport';
import FormTitlesReportTemplate from '../screens/TitlesReport/search';
import VehicleSupplyReport from '../screens/VehicleSupplyReport';
import FormVehicleSupplyReportTemplate from '../screens/VehicleSupplyReport/search';
import FormProductsReportTemplate from '../screens/ProductReport/search';
import InterDepositTransfer from '../screens/InterDepositTransfer';
import StockExtractReport from '../screens/StockExtractReport';
import StockExtractReportSearch from '../screens/StockExtractReport/search';
import StockBalanceReport from '../screens/StockBalanceReport';
import StockBalanceReportSearch from '../screens/StockBalanceReport/search';
import SellingReport from '../screens/SellingReport';
import FormSellingReportTemplate from '../screens/SellingReport/search';
import ClassesReport from '../screens/ClassesReport';
import ClassesReportSearch from '../screens/ClassesReport/search';
import Requests from '../screens/Requests';
import FormPurchaseReportTemplate from '../screens/PurchaseReport/search';

interface IPrivateRoute {
	component: React.FC<any>;
	path: string;
	exact?: boolean;
	customProps?: any;
};

const PrivateRoute: React.FC<IPrivateRoute> = ({ component, path, exact = false, customProps = {} }) => {
  const {signed} = useAuth();

  const Component = component;
	return (
		<Route
			exact={exact}
			path={path}
			render={(props: RouteComponentProps) =>
				signed ? (
					<Component {...props} {...customProps} />
				) : (
					<Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
					/>
				)
			}
		/>
	);
};

const renderCRUDLRoutes = () => {
	const routes = [
		<PrivateRoute key="home" exact path={AuthRoutes.home} component={Home} />,
        <PrivateRoute key="users" exact path={AuthRoutes.users} component={Users} />,
		<PrivateRoute key="class" exact path={AuthRoutes.classes_explorer} component={ClassExplorer} />,
		<PrivateRoute key="insert_sales_order" exact path={AuthRoutes.sales_order} component={SalesOrder} />,
		<PrivateRoute key="insert_pull_order" exact path={InsertRoutes.insert_pull_order} component={PurchaseOrder} />,
		<PrivateRoute key="edit_sales_order" exact path={AuthRoutes.edit_sales_order} component={SalesOrder} />,
		<PrivateRoute key="insert_purchase_service_order" exact path={AuthRoutes.purchase_service_order} component={PurchaseServiceOrder} />,
		<PrivateRoute key="insert_selling_service_order" exact path={AuthRoutes.selling_service_order} component={SellingServiceOrder} />,
		<PrivateRoute exact key="espelho-pedido-saida" path={AuthRoutes.mirror_sales_order} component={EspelhoPedido} />,
		<PrivateRoute exact key="espelho-pedido-entrada" path={AuthRoutes.mirror_purchase_order} component={EspelhoPedido} />,
		<PrivateRoute exact key="espelho-pedido-compra-servico" path={AuthRoutes.mirror_purchase_service_order} component={EspelhoPedido} />,
		<PrivateRoute exact key="espelho-pedido-venda-servico" path={AuthRoutes.mirror_selling_service_order} component={EspelhoPedido} />,
		<PrivateRoute exact key="requisicoes/inserir" path={InsertRoutes.insert_request} component={NewRequest} />,
		<PrivateRoute 
			exact 
			key="requisicoes/editar" 
			path={EditRoutes.edit_request} 
			component={NewRequest} 
			/>,
		<PrivateRoute 
			exact 
			key="requisicoes/resultado" 
			path={AuthRoutes.request_result} 
			component={Requests} 
			customProps={{
				title: 'Requisições',
				endpoint: 'requisicoes',
				path: AuthRoutes.request_result,
				multipleSelect: true,
				filters: {
					classe: "Requisições"
				},
				actions: [
					{
						id: "edit",
						name: "Editar",
						type: "specific",
						onClick: null,
					},
				]
			}}
		/>,
		<PrivateRoute
			exact 
			key="requisicoes" 
			path={AuthRoutes.request} 
			component={FormTemplateRequest} 
			customProps={{
				title: 'Requisições',
				endpoint: 'requisicoes',
				path: AuthRoutes.request,
				filters: {
					classe: "Requisições",
					search: true
				},
				actions: [
					{
						id: 'insert',
						name: 'Inserir Novo Requerimento',
						type: 'specific',
						onClick: InsertRoutes.insert_request,
					}
				]
			}}
			/>,
		<PrivateRoute 
			key="product_report" 
			exact 
			path={AuthRoutes.product_report} 
			component={FormProductsReportTemplate}
			customProps={{
				title: 'Relatório de Produtos',
				path: AuthRoutes.product_report,
				actions: [
					{
						id: "edit",
						name: "Editar",
						type: "specific",
						onClick: null,
					},
					{
						id: "remove",
						name: "Excluir",
						type: "specific",
						onClick: null,
					},
				],
				endpoint: 'recursos',
				filters: {
					classe: 'Produtos',
					search: true,
				}

			}}
		/>,
		<PrivateRoute 
			key="result_product_report" 
			exact 
			path={AuthRoutes.result_product_report} 
			component={ProductsReport}
			customProps={{
				title: 'Relatório de Produtos',
				path: AuthRoutes.result_product_report,
				endpoint: 'recursos',
				filters: {
					classe: 'Produtos',
				}

			}}
		/>,
		<PrivateRoute 
			key="order_buying_report" 
			exact 
			path={AuthRoutes.order_buying_report} 
			component={FormPurchaseReportTemplate}
			customProps={{
				title: 'Relatório de Entradas',
				path: AuthRoutes.order_buying_report,
				actions: [
					{
						id: "edit",
						name: "Editar",
						type: "specific",
						onClick: null,
					},
					{
						id: "remove",
						name: "Excluir",
						type: "specific",
						onClick: null,
					},
				],
				endpoint: 'pedidos',
				filters: {
					classe: 'Gastos',
					report: true,
				}

			}}
		/>,
		<PrivateRoute 
			key="result_order_buying_report" 
			exact 
			path={AuthRoutes.result_order_buying_report} 
			component={PurchaseReport}
			customProps={{
				title: 'Relatório de Entradas',
				path: AuthRoutes.result_order_buying_report,
				endpoint: 'recursos',
				filters: {
					classe: 'Gastos',
				}

			}}
		/>,
		<PrivateRoute 
			key="conductors_report" 
			exact 
			path={AuthRoutes.conductors_report} 
			component={FormConductorsReportTemplate}
			customProps={{
				title: 'Relatório de Motoristas',
				path: AuthRoutes.conductors_report,
				actions: [
					{
						id: "edit",
						name: "Editar",
						type: "specific",
						onClick: null,
					},
					{
						id: "remove",
						name: "Excluir",
						type: "specific",
						onClick: null,
					},
				],
				endpoint: 'entidades',
				filters: {
					classe: 'Motoristas',
					search: true,
				}

			}}
		/>,
		<PrivateRoute 
			key="result_conductors_report" 
			exact 
			path={AuthRoutes.result_conductors_report} 
			component={ConductorsReport}
			customProps={{
				title: 'Relatório de Motoristas',
				path: AuthRoutes.result_conductors_report,
				endpoint: 'entidades',
				filters: {
					classe: 'Motoristas',
				}

			}}
		/>,
		<PrivateRoute 
			key="vehicle_report" 
			exact 
			path={AuthRoutes.vehicle_report} 
			component={FormVehicleReportTemplate}
			customProps={{
				title: 'Relatório de Veículos',
				path: AuthRoutes.vehicle_report,
				actions: [
					{
						id: "edit",
						name: "Editar",
						type: "specific",
						onClick: null,
					},
					{
						id: "remove",
						name: "Excluir",
						type: "specific",
						onClick: null,
					},
				],
				endpoint: 'recursos',
				filters: {
					classe: 'Veículos',
					search: true,
				}

			}}
		/>,
		<PrivateRoute 
			key="result_vehicle_report" 
			exact 
			path={AuthRoutes.result_vehicle_report} 
			component={VehicleReport}
			customProps={{
				title: 'Relatório de Veículos',
				path: AuthRoutes.result_vehicle_report,
				endpoint: 'recursos',
				filters: {
					classe: 'Veículos',
				}

			}}
		/>,
		<PrivateRoute 
			key="vehicle_supply_report" 
			exact 
			path={AuthRoutes.vehicle_supply_report} 
			component={FormVehicleSupplyReportTemplate}
			customProps={{
				title: 'Relatório de Abastecimentos',
				path: AuthRoutes.vehicle_supply_report,
				actions: [
					{
						id: "edit",
						name: "Editar",
						type: "specific",
						onClick: null,
					},
					{
						id: "remove",
						name: "Excluir",
						type: "specific",
						onClick: null,
					},
				],
				endpoint: 'tabela',
				filters: {
					classe: 'Abastecimento Veículo',
					search: true,
				}

			}}
		/>,
		<PrivateRoute 
			key="result_vehicle_supply_report" 
			exact 
			path={AuthRoutes.result_vehicle_supply_report} 
			component={VehicleSupplyReport}
			customProps={{
				title: 'Relatório de Abastecimentos',
				path: AuthRoutes.result_vehicle_supply_report,
				endpoint: 'tabela',
				filters: {
					classe: 'Abastecimento Veículo',
				}

			}}
		/>,
		<PrivateRoute 
			key="titles_report" 
			exact 
			path={AuthRoutes.titles_report} 
			component={FormTitlesReportTemplate}
			customProps={{
				title: 'Relatório de Títulos',
				path: AuthRoutes.titles_report,
				actions: [
					{
						id: "edit",
						name: "Editar",
						type: "specific",
						onClick: null,
					},
					{
						id: "remove",
						name: "Excluir",
						type: "specific",
						onClick: null,
					},
				],
				endpoint: 'titulos',
				filters: {
					classe: 'Títulos',
					search: true,
				}

			}}
		/>,
		<PrivateRoute 
			key="result_titles_report" 
			exact 
			path={AuthRoutes.result_titles_report} 
			component={TitlesReport}
			customProps={{
				title: 'Relatório de Títulos',
				path: AuthRoutes.result_titles_report,
				endpoint: 'titulos',
				filters: {
					classe: 'Títulos',
				}

			}}
		/>,
		<PrivateRoute 
			key="order_selling_report" 
			exact 
			path={AuthRoutes.order_selling_report} 
			component={FormSellingReportTemplate}
			customProps={{
				title: 'Relatório de Saída',
				path: AuthRoutes.order_selling_report,
				actions: [
					{
						id: "edit",
						name: "Editar",
						type: "specific",
						onClick: null,
					},
					{
						id: "remove",
						name: "Excluir",
						type: "specific",
						onClick: null,
					},
				],
				endpoint: 'pedidos',
				filters: {
					classe: 'Rendas',
					report: true,
				}

			}}
		/>,
		<PrivateRoute 
			key="result_order_selling_report" 
			exact 
			path={AuthRoutes.result_order_selling_report} 
			component={SellingReport}
			customProps={{
				title: 'Relatório de Saídas',
				path: AuthRoutes.result_order_selling_report,
				endpoint: 'relatorios',
				filters: {
					classe: 'Rendas',
				}

			}}
		/>,
		<PrivateRoute 
			key="providers_report" 
			exact 
			path={AuthRoutes.providers_report} 
			component={FormProvidersReportTemplate}
			customProps={{
				title: 'Relatório de Fornecedores',
				path: AuthRoutes.providers_report,
				actions: [
					{
						id: "edit",
						name: "Editar",
						type: "specific",
						onClick: null,
					},
					{
						id: "remove",
						name: "Excluir",
						type: "specific",
						onClick: null,
					},
				],
				endpoint: 'entidades',
				filters: {
					classe: 'Fornecedores',
					search: true,
				}

			}}
		/>,
		<PrivateRoute 
			key="result_providers_report" 
			exact 
			path={AuthRoutes.result_providers_report} 
			component={ProvidersReport}
			customProps={{
				title: 'Relatório de Fornecedores',
				path: AuthRoutes.result_providers_report,
				endpoint: 'entidades',
				filters: {
					classe: 'Fornecedores',
				}

			}}
		/>,
		<PrivateRoute
			exact 
			key="import_providers"
			path={AuthRoutes.import_providers}
			component={ImportTemplate} 
			customProps={{
				title: 'fornecedores',
				endpoint: 'entidades',
				filters: {
					idClasse: '61cd2730ad47e0a02eab67da',
					classe: "Fornecedores",
				},
				extraction: importData.fornecedores,
			}} 
		/>,
		<PrivateRoute
			exact 
			key="import_products"
			path={AuthRoutes.import_products}
			component={ImportTemplate} 
			customProps={{
				title: 'produtos',
				endpoint: 'recursos',
				filters: {
					idClasse: '61cd2732ad47e0a02eab67e3',
					classe: "Produtos",
				},
				extraction: importData.produtos,
			}} 
		/>,
		<PrivateRoute
			exact 
			key="result_pull_order"
			path={AuthRoutes.result_pull_order}
			component={Template} 
			customProps={{
				title: 'Resultados da busca de baixa de pedido',
				path: AuthRoutes.result_pull_order,
				pullOrderPath: InsertRoutes.insert_pull_order,
				multipleSelect: true, 
				actions: [
					{
						id: "pullOrder",
						name: "Baixar pedido",
						type: "global",
						onClick: null,
					},
					{
						id: "edit",
						name: "Editar",
						type: "specific",
						onClick: null,
					},
				],
				endpoint: 'pedidos',
				filters: {
					classe: "Pedidos",
					aprovado: true,
				},
			}} 
		/>,
		<PrivateRoute
			exact 
			key="result_sales_order"
			path={AuthRoutes.result_sales_order}
			component={Template} 
			customProps={{
				title: 'Resultados da busca de pedidos de saída',
				path: AuthRoutes.result_sales_order,
				pullOrderPath: InsertRoutes.insert_selling_order,
				multipleSelect: true, 
				actions: [
					{
						id: "pullOrder",
						name: "Baixar pedido",
						type: "global",
						onClick: null,
					},
					{
						id: "espelhoPedido",
						name: "Espelho de Pedido",
						type: "specific",
						onClick: null,
					},
					{
						id: "edit",
						name: "Editar",
						type: "specific",
						onClick: null,
					},
				],
				endpoint: 'pedidos',
				filters: {
					classe: "Rendas",
				},
			}} 
		/>,
		<PrivateRoute
			exact 
			key="result_purchase_service_order"
			path={AuthRoutes.result_purchase_service_order}
			component={Template} 
			customProps={{
				title: 'Resultados da busca de pedidos de compra de serviço',
				path: AuthRoutes.result_purchase_service_order,
				pullOrderPath: InsertRoutes.insert_purchase_service_order,
				actions: [
					{
						id: "pullOrder",
						name: "Baixar pedido",
						type: "global",
						onClick: null,
					},
					{
						id: "edit",
						name: "Editar",
						type: "specific",
						onClick: null,
					},
				],
				endpoint: 'pedidos',
				filters: {
					classe: "Gastos",
				},
			}} 
		/>,
		<PrivateRoute
			exact 
			key="result_selling_service_order"
			path={AuthRoutes.result_selling_service_order}
			component={Template} 
			customProps={{
				title: 'Resultados da busca de pedidos de venda de serviço',
				path: AuthRoutes.result_selling_service_order,
				pullOrderPath: InsertRoutes.insert_selling_service_order,
				actions: [
					{
						id: "pullOrder",
						name: "Baixar pedido",
						type: "global",
						onClick: null,
					},
					{
						id: "edit",
						name: "Editar",
						type: "specific",
						onClick: null,
					},
				],
				endpoint: 'pedidos',
				filters: {
					classe: "Rendas",
				},
			}} 
		/>,
		<PrivateRoute
			exact 
			key="result_purchase_order"
			path={AuthRoutes.result_purchase_order}
			component={Template} 
			customProps={{
				title: 'Resultados da busca de pedidos de entrada',
				path: AuthRoutes.result_purchase_order,
				actions: [
					{
						id: "espelhoPedido",
						name: "Espelho de Pedido",
						type: "specific",
						onClick: null,
					},
					{
						id: "edit",
						name: "Editar",
						type: "specific",
						onClick: null,
					},
				],
				endpoint: 'pedidos',
				filters: {
					classe: "Gastos",
				},
			}} 
		/>,
		<PrivateRoute
			exact 
			key="stock_balance"
			path={AuthRoutes.stock_balance}
			component={StockBalanceReportSearch} 
			customProps={{
				title: 'Saldo de Estoque',
				path: AuthRoutes.stock_balance,
				endpoint: 'movdepos',
				filters: {
				  report: true,
				},
			}} 
		/>,
		<PrivateRoute
		exact 
		key="stock_balance_report"
		path={AuthRoutes.stock_balance_report}
		component={StockBalanceReport} 
		customProps={{
			title: 'Saldo de Estoque',
		}} 
		/>,
		<PrivateRoute 
			key="extract_balance" 
			exact 
			path={AuthRoutes.extract_balance} 
			component={StockExtractReportSearch}
			customProps={{
				title: 'Extrato de Estoque',
				path: AuthRoutes.extract_balance,
				endpoint: 'movdepos',
				filters: {
					classe: 'Extrato',
					report: true,
				}
			}}
		/>,
		<PrivateRoute 
			key="stock_extract_balance_report" 
			exact 
			path={AuthRoutes.stock_extract_balance_report} 
			component={StockExtractReport}
			customProps={{
				title: 'Extrato de Estoque',
				path: AuthRoutes.stock_extract_balance_report,
				endpoint: 'movdepos',
				filters: {
					classe: 'Extrato',
				}
			}}
		/>,
		<PrivateRoute 
			key="classes_report_search" 
			exact 
			path={AuthRoutes.classes_report_search} 
			component={ClassesReportSearch}
			customProps={{
				title: 'Listagem de Classes',
				path: AuthRoutes.classes_report_search,
				endpoint: 'classes',
				filters: {
					report: true,
				}
			}}
		/>,
		<PrivateRoute 
			key="classes_report" 
			exact 
			path={AuthRoutes.classes_report} 
			component={ClassesReport}
			customProps={{
				title: 'Listagem de Classes',
				path: AuthRoutes.classes_report_search,
				endpoint: 'classes',
				filters: {}
			}}
		/>,
		<PrivateRoute 
			exact
			key="ficha-funcionario"
			path={AuthRoutes.employee_file}
			component={FichaFuncionario}
		/>,
		<PrivateRoute key="order_down" exact path={AuthRoutes.purchase_order} component={PurchaseOrder} />,
		<PrivateRoute key="edit_order_down" exact path={AuthRoutes.edit_purchase_order} component={PurchaseOrder} />,
		<PrivateRoute key="light_release" exact path={AuthRoutes.light_release} component={SalesOrder} customProps={{baixaAutom: true}}/>,
		<PrivateRoute
			exact 
			key="title" 
			path={AuthRoutes.title} 
			component={TitleFormTemplateRequest} 
			customProps={{
				title: 'Títulos',
				endpoint: 'titulos',
				path: AuthRoutes.title,
				actions: [
					{
						id: "insert",
						name: "Novo título",
						type: "global",
						onClick: null,
					},
				],
				filters: {
					classe: "Títulos",
					search: true
				},
			}}
			/>,
		<PrivateRoute key="accounting_plan" exact path={AuthRoutes.accounting_plan} component={AccountPlan} />,
		<PrivateRoute key="accounting_entry" exact path={AuthRoutes.accounting_entry} component={AccountingEntry} />,
		<PrivateRoute key="inventory" exact path={AuthRoutes.inventory} component={Inventory} />,
		<PrivateRoute key="order_report" exact path={AuthRoutes.order_report} component={OrderReport} />,
	];

	for (const page of pages) {
		const {
			key,
			labels,
			endpoint,
			filters,
			customFields,
			editCustomFields,
			startWithSearch,
		  } = page;
		  const insertKey: keyof typeof InsertRoutes = `insert_${key}`;
		  const editKey: keyof typeof EditRoutes = `edit_${key}`;

		  if (startWithSearch) {
			routes.push((
				<PrivateRoute
					exact 
					key={key}
					path={ListRoutes[key]}
					component={SearchTemplate} 
					customProps={{
						title: labels.list,
						actions: filters.classe && filters.classe === 'Baixa' ? [] : [
							{
								id: "insert",
								name: "Novo pedido",
								type: "global",
								onClick: InsertRoutes[insertKey],
							},
							{
								id: "insert",
								name: "Novo com baixa automática",
								type: "global",
								onClick:  InsertRoutes[insertKey],
								params: {
									baixaAutomatica: true,
								}
							},
						],
						path: ListRoutes[key],
						endpoint,
						filters,
						customFields,
						editCustomFields,
					}} 
				/>));
		  } else {
			let actions = [
				{
					id: "insert",
					name: "Inserir",
					type: "global",
					onClick: null,
				},
				{
					id: "edit",
					name: "Editar",
					type: "specific",
					onClick: null,
				},
				{
					id: "remove",
					name: "Excluir",
					type: "specific",
					onClick: null,
				},
			];
			if (customFields.insert && customFields.insert === false) {
				actions = actions.filter(action => action.id !== 'insert');
			}

			let _customFields = customFields.actions || [];
			routes.push((
				<PrivateRoute
					exact 
					key={key}
					path={ListRoutes[key]}
					component={Template} 
					customProps={{
						title: labels.list,
						path: ListRoutes[key],
						actions: [
							{
								id: "insert",
								name: "Inserir",
								type: "global",
								onClick: null,
							},
							..._customFields,
							{
								id: "edit",
								name: "Editar",
								type: "specific",
								onClick: null,
							},
							{
								id: "remove",
								name: "Excluir",
								type: "specific",
								onClick: null,
							},
						],
						endpoint,
						filters,
						customFields,
					}} 
				/>));
			routes.push((
				<PrivateRoute
					exact 
					key={insertKey}
					path={InsertRoutes[insertKey]} 
					component={FormTemplate} 
					customProps={{
						title: labels.insert,
						endpoint,
						filters,
						customFields,
					}} 
				/>
			));
			routes.push((
				<PrivateRoute
					exact 
					key={editKey}
					path={EditRoutes[editKey]} 
					component={FormTemplate} 
					customProps={{
						title: labels.edit,
						endpoint,
						filters,
						customFields,
						editCustomFields,
					}} 
				/>
			));
		  }
	}
	routes.push(
		<PrivateRoute
			exact 
			key="stock_move_search" 
			path={AuthRoutes.stock_move_search} 
			component={FormTemplateRequest} 
			customProps={{
				title: 'Movimentação de Depósitos',
				endpoint: 'movdepos',
				path: AuthRoutes.stock_move_search,
				filters: {
					classe: "Movimentação de Depósitos",
					search: true
				},
			}}
			/>,
	);
	routes.push((
		<PrivateRoute
		exact 
		key={'stock_move'}
		path={AuthRoutes.stock_move}
		component={Template} 
		customProps={{
			title: 'Movimentação de Depósitos',
			path: AuthRoutes.stock_move,
			actions: [
				{
					id: "insert",
					name: "Inserir",
					type: "global",
					onClick: null,
				},
				{
					id: "remove",
					name: "Excluir",
					type: "specific",
					onClick: null,
				},
			],
			endpoint: 'movdepos',
			filters: {
				classe: 'Movimentação de Depósitos',
				recpedtra: 'T',
			}
		}}
	/>));
	routes.push(<PrivateRoute key="insert_stock_move" exact path={InsertRoutes.insert_stock_move} component={InterDepositTransfer} />);
	return routes;
}

const Routes: React.FC<any> = ({initialPath, ...props}) => {
  const history = useHistory();

  useEffect(() => {
	if (initialPath) {
		history.push(initialPath);
	}
  }, [initialPath]);

  return (
    <Switch>
		{renderCRUDLRoutes()}
    </Switch>
  );
};

export default Routes;