/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import { RouteComponentProps } from 'react-router-dom';
import provider from '../../../services/config';

import {FormWrapper, HeaderContent, Title, FormContent, UploadInput, UploadLabel, UploadedContent, UploadButtonContainer, UploadedItemActions, NoPrivideContent} from '../styles';
import { useAuth } from '../../../contexts/auth';
import Toast from '../../Toast';
import Button from '../../Button';
import { IoPencil } from 'react-icons/io5';
import FormTemplate from '../forms';
import { useFormModal } from '../../../contexts/formModal';
import ProgressBar from '../../ProgressBar';
import { BarContainer } from '../../ProgressBar/style';

interface Props extends RouteComponentProps {
  title: string;
  endpoint: string;
  filters: any;
  extraction: any;
}

const Import: React.FC<Props> = ({
  title,
  endpoint,
  filters,
  extraction,
  history
}) => {
  const {user} = useAuth();
  const {openModal, closeModal} = useFormModal();
  const [files, setFiles] = useState<any>([]);
  const [toUpload, setToUpload] = useState<any[]>([]);
  const [inserteds, setInserteds] = useState<any[]>([]);
  const [showLinkProvider, setShowLinkProvider] = useState(false);
  const [loading, setLoading] = useState(false);
  const [percentUpload, setPercentUpload] = useState(0);
  const [noProvideList, setNoProvideList] = useState([]);

  useEffect(() => {
    if (toUpload && toUpload.length > 0) {
      upload(toUpload);
    }
  }, [toUpload]);

  function handleFinish() {
    setFiles([]);
    setToUpload([]);
    setInserteds([]);
    Toast.show(`${title} Criados com Sucesso!`, "success");
  }

  async function getUF(nome: string) {
    try {
      const result = await provider.get('tabela', {
        params: {
          classe: 'Unidades da Federação',
          nome,
        },
        headers: {
          Authorization: `Bearer ${user?.token}`
        }
      });

      if (Array.isArray(result.data) && result.data.length > 0) {
        const filter = result.data.filter((uf) => uf.codigo === nome)[0];
        
        if (filter) {
          return filter.id;
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (err) {
      Toast.show(`UF "${nome}" não cadastrada na nossa base`, 'error');
    }
  }

  async function getMunicipio(nome: string, uf?: string) {
    try {
      const params: any = {
        classe: 'Municípios',
        nome,
      };

      if (uf) {
        params.uf = uf;
      }

      const result = await provider.get('tabela', {
        params,
        headers: {
          Authorization: `Bearer ${user?.token}`
        }
      });

      if (Array.isArray(result.data) && result.data.length > 0) {
        return result.data[0].id;
      } else {
        return null;
      }
    } catch (err) {
    }
  }

  async function searchUFCitiesByCode(codigoMunicipio: string, uf?: string) {
    try {
      const params: any = {
        classe: 'Municípios',
      };

      if (uf) {
        params.uf = uf;
      }

      const result = await provider.get('tabela', {
        params,
        headers: {
          Authorization: `Bearer ${user?.token}`
        }
      });

      if (Array.isArray(result.data) && result.data.length > 0) {
        
        const city = result.data.filter((municipio) => municipio.codigoIBGE === codigoMunicipio)[0];

        if (city) {
          return city.id;
        } else {
          return null;
        }

      } else {
        return null;
      }
    } catch (err: any) {
      console.log(err.message);
    }
  }

  async function getUnidadeMedida(nome: string) {
    try {
      const result = await provider.get('tabela', {
        params: {
          classe: 'Unidades de Medidas',
          nome
        },
        headers: {
          Authorization: `Bearer ${user?.token}`
        }
      });
      if (Array.isArray(result.data) && result.data.length > 0) {
        const filter = result.data.filter((uni) => uni.codigo === nome)[0];
        
        if (filter) {
          return filter.id;
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (error) {
      Toast.show(`Unidade de Medida "${nome}" não cadastrada na nossa base`, 'error');
    }
  }

  async function getNCM(nome: string) {
    try {
      const result = await provider.get('tabela', {
        params: {
          classe: 'NCM - Nomenclatura Comum do Mercosul',
          nome
        },
        headers: {
          Authorization: `Bearer ${user?.token}`
        }
      });
      
      if (Array.isArray(result.data) && result.data.length > 0) {
        const filter = result.data.filter((NCM) => NCM.codigo === nome)[0];
        
        if (filter) {
          return filter.id;
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (error) {
      Toast.show(`NCM - Nomenclatura Comum do Mercosul "${nome}" não cadastrada na nossa base`, 'error');
    }
  }

  async function getProvider(nome: string) {
    try {
      const result = await provider.get('entidades', {
        params: {
          nome
        },
        headers: {
          Authorization: `Bearer ${user?.token}`
        }
      });
      return result.data;
    } catch (error) {
      Toast.show(`Fornecedor "${nome}" não cadastrada na nossa base`, 'error');
      setShowLinkProvider(true);
    }
  }

  async function getProcedencia(nome: string) {
    try {
      const result = await provider.get('tabela', {
        params: {
          nome,
          classe: 'Procedência'
        },
        headers: {
          Authorization: `Bearer ${user?.token}`
        }
      });
      if (Array.isArray(result.data) && result.data.length > 0) {
        const filter = result.data.filter((uni) => uni.codigo === nome)[0];
        
        if (filter) {
          return filter.id;
        } else {
          return null;
        }
      } else {
        return null;
      }
      return result.data;
    } catch (error) {
      Toast.show(`Procedência ${nome} não encontrada na base`, 'error');
    }
  }

  function mascaraCpf(valor: string) {
    return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g,"\$1.\$2.\$3\-\$4");
  }

  function mascaraCEP(valor: string) {
    return valor.replace(/(\d{5})(\d{3})/g,"\$1\-\$2");
  }

  function mascaraCnpj(valor: string) {
      return valor.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,"\$1.\$2.\$3\/\$4\-\$5");
  }

  async function readXML(file: any) {
    try {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        
        reader.readAsText(file);
        reader.onloadend = evt => {
          if (evt && evt.target) resolve(evt.target.result);
          else reject();
        }
      })
    } catch (err: any) {
      console.log(err.message);
    }
  }

  async function readXMLText(file: any) {
    try {
      const fileTxt: any = await readXML(file);
      const parser = new DOMParser();
      const xml = parser.parseFromString(fileTxt, 'text/xml');
      return xml;
    } catch (err: any) {
      console.log(err.message);
    }
  }

  async function handleFormatXML(_files: FileList | null) {
    setLoading(true);
    if (_files) {
      let toInsertArray: any[] = [];
      const totalFiles = _files.length;
      for (let i = 0; i < totalFiles; i++) {
        const file = _files[i];
        const data = await readXMLText(file);
        if (data) {
          if(title === 'produtos') {
            const currentInsert = await handleProductsTagXML(data);
            toInsertArray = [...toInsertArray, ...currentInsert];
          } else if (title === 'fornecedores') {
            const currentInsert = await handleProviderTagXML(data);
            toInsertArray = [...toInsertArray, ...currentInsert];
          }
        }

        const _percent = (i+1)/totalFiles * 100;
        setPercentUpload(_percent);
      }

      setFiles(Array.from(_files));
      setToUpload(toInsertArray);
    }
  }

  async function handleProviderTagXML(data: any) {
    const toInsertArray: any[] = [];
    let toInsertData: any = {
      idClasse: filters.idClasse,
    };
  
    const rootNode = data.querySelector(extraction.rootNode);
    if (rootNode) {
      const extractionKeys = Object.keys(extraction.keys);
      for (let j = 0; j < extractionKeys.length; j++) {
        const key = extractionKeys[j];
        const keyData = rootNode.querySelector(key);

        if (keyData) {
          let value = keyData.innerHTML;
          if (key === 'CNPJ') {
            value = mascaraCnpj(keyData.innerHTML);
            toInsertData.codigo = value;
          } else if (key === 'CEP') {
            value = mascaraCEP(keyData.innerHTML);
          } else if (key === 'UF') {
            value = await getUF(keyData.innerHTML);
          } else if (key === 'xMun') {
            value = await getMunicipio(keyData.innerHTML, toInsertData?.idUF);

            if (value === null) {
              value = await searchUFCitiesByCode(rootNode.querySelector('cMun').innerHTML, toInsertData?.idUF);
            }
          }
          toInsertData[extraction.keys[key].field] = value;
        }
      }

      if (Object.keys(toInsertData).length > 0) {
        toInsertArray.push(toInsertData);
      }
    }

    const transportNode = data.querySelector('transporta');
    if (transportNode) {
      const transportData: any = {
        idClasse: '626c0fd9e729a1df99297052',
      };
      const extFields: any = {
        CNPJ: {
          field: 'cpfCnpj',
          label: 'CPF/CNPJ.....',
        },
        xNome: {
          field: 'nome',
          label: 'Nome.........',
        },
        IE: {
          field: 'inscricaoEstadual',
          label: 'Insc Estadual',
        },
        xEnder: {
          field: 'endereco',
          label: 'Endereço.....',
        },
        UF: {
          field: 'idUF',
          label: 'UF...........',
        },
        xMun: {
          field: 'idCidade',
          label: 'Município....',
        },
      };
      const extractionKeys = Object.keys(extFields);
      for (let j = 0; j < extractionKeys.length; j++) {
        const key = extractionKeys[j];
        const keyData = transportNode.querySelector(key);

        if (keyData) {
          let value = keyData.innerHTML;
          if (key === 'CNPJ') {
            value = mascaraCnpj(keyData.innerHTML);
            transportData.codigo = value;
          } else if (key === 'CEP') {
            value = mascaraCEP(keyData.innerHTML);
          } else if (key === 'UF') {
            value = await getUF(keyData.innerHTML);
          } else if (key === 'xMun') {
            value = await getMunicipio(keyData.innerHTML, transportData?.idUF);

            if (value === null) {
              value = await searchUFCitiesByCode(transportNode.querySelector('cMun').innerHTML, transportData?.idUF);
            }
          }
          transportData[extFields[key].field] = value;
        }
      }

      if (Object.keys(transportData).length > 0) {
        toInsertArray.push(transportData);
      }
    }
    return toInsertArray;
  }

  async function handleProductsTagXML(data: any) {
    const toInsertArray: any[] = [];
    const provideNode = data.querySelector('emit');
    const detNodes = data.querySelectorAll('det'); 
    let idFornecedor = null;

    if(provideNode) {
      const cnpj = provideNode.querySelector('CNPJ');
      const nameProvide = provideNode.querySelector('xNome')
      if(cnpj) {
        let _provider = await getProvider(cnpj.innerHTML);
        if(_provider && _provider[0]) {
          idFornecedor = _provider[0].id;
        } else {
          const newProvideList:any = [...noProvideList,  {name: nameProvide.innerHTML, cnpj: cnpj.innerHTML}];
          setNoProvideList(newProvideList);

          setShowLinkProvider(true);
          return [];
        }
      }
    }

    if(detNodes) {
      let toInsertData: any = {
        idClasse: filters.idClasse,
      };
      const extractionKeys = Object.keys(extraction.keys);
      
      for(let t=0; t < detNodes.length; t++) {
        toInsertData = {
          idClasse: filters.idClasse,
        }
        const currentNode = detNodes[t];
        const prodNode = currentNode.querySelector('prod');
        const origData = currentNode.querySelector('orig') ?? currentNode.querySelector('Orig');
        
        for (let j = 0; j < extractionKeys.length; j++) {
          const key = extractionKeys[j];
          const keyData = prodNode.querySelector(key);
          
          if (keyData) {
            let value = keyData.innerHTML;
            if (key === 'uTrib') {
              value = await getUnidadeMedida(keyData.innerHTML)
            } else if (key === 'NCM') {
              value = await getNCM(keyData.innerHTML);
            }

            toInsertData[extraction.keys[key].field] = value;
          } else if(key === 'uTrib') {
            const keyData = prodNode.querySelector('uCom');
            let value = keyData.innerHTML;
            if(keyData) {
              value = await getUnidadeMedida(keyData.innerHTML)
            }

            toInsertData[extraction.keys[key].field] = value;
          }
        }

        if(origData) {
          let value = origData.innerHTML;
          value = await getProcedencia(value);
          if(value) {
            toInsertData['idProcedencia'] = value;
          }
        }

        if (Object.keys(toInsertData).length > 0) {
          toInsertData["idFornecedor"] = idFornecedor;
          toInsertArray.push(toInsertData);
        }
      }
    }
    return toInsertArray;
  }
  
  async function upload(toInsert: any[]) {
    for (let i = 0; i < toInsert.length; i++) {
      try {
          const insertItem = toInsert[i];
          const result = await provider.post(endpoint, insertItem, {
            headers: {
              Authorization: `Bearer ${user?.token}`
            }
          });
          setInserteds((oldData: any) => {
            return [...oldData, result.data];
          });
      } catch (err: any) {
        const file: File = files[i];

        if(file) {
          Toast.show(`Ocorreu ao tentar salvar o arquivo ${file.name}`, 'error');
        }
        if (err.response && err.response.data && err.response.data.message) {
          Toast.show(err.response.data.message, 'error');
        }
      }
    }
    
    setLoading(false);
  }

  function handleSubmitModal(item: any, ind: any) {
    setInserteds((oldData: any[]) => {
      return oldData.map((dataItem: any, index: any) => {
        if (index === ind) {
          return {
            ...dataItem,
            ...item,
          }
        } else {
          return dataItem;
        }
      });
    })
    closeModal();
  }

  function handleOpenModal(item: any, index: number) {
    openModal({
      type: 'confirm',
      title: `Editar`,
      children: () => <FormTemplate 
        filters={{...filters}}
        customFields={{}}
        title={title}
        endpoint={`${endpoint}`} 
        onSubmitCallback={(item: any) => handleSubmitModal(item, index)}
        modalItem={item}/>,
    })
  }

  function goToImportProvide() {
    history.push('/importar-fornecedores');
  }

  return (
    <FormWrapper>
      <HeaderContent>
        <Title>Importar {title}</Title>
      </HeaderContent>
      <FormContent>
        <UploadButtonContainer>
          <p>Selecione os arquivos para importação. Os arquivos devem ter o formato XML.</p>
          {showLinkProvider && <strong>Um ou mais fornecedores não estão cadastrado na nossa base. Acesse <span onClick={goToImportProvide}>Importar Fornecedores</span> para importá-lo.</strong>}
          <UploadInput 
            type="file" 
            name="file" 
            id="file"
            accept="text/xml"
            multiple
            onChange={({currentTarget}) => handleFormatXML(currentTarget.files)}
            disabled={loading}
          />
          <UploadLabel htmlFor="file">
            {loading ? 
              'Carregando...' : 
              toUpload.length > 0 ? 
                `Arquivo(s) selecionados` : 
                'Selecione os arquivos'
            }
          </UploadLabel>
          {loading ? <ProgressBar width={100} childWidth={percentUpload} /> : null}
        </UploadButtonContainer>
        {noProvideList.length>0 && <div>
          <p style={{marginBottom: 10}}>Os seguintes Fonecedores não estão cadastrados na base:</p>
          <NoPrivideContent>
            {noProvideList.map((item:any) => (<>
                <div className='flex'>
                  <span>CNPJ: <p style={{fontWeight: 400}}>{item.cnpj}</p></span> <span>nome: <p style={{fontWeight: 400}}>{item.name}</p></span>
                </div>
            </>
            ))}
          </NoPrivideContent>
        </div>}
        {inserteds.length > 0 && (
          <>
  
          <br />
          <br />
          <p><b>Adicionados:</b></p>
          {inserteds.map((inserted, index) => (
            <div key={inserted.id}>
              <UploadedItemActions>
                <p><span>ID #{inserted.id}</span></p>
                <IoPencil style={{cursor: 'pointer', marginLeft: 8}} type="button" size={16} onClick={() => handleOpenModal(inserted, index)} color="var(--primary-text-color)" /> 
              </UploadedItemActions>
              <UploadedContent>
                <table>
                  <tbody>
                    {Object.keys(extraction.keys).map((key: string) => {
                      let field = extraction.keys[key].field;
                      let child = extraction.keys[key].child;
                      const label = extraction.keys[key].label;
                      let value = inserted[field];
                      if (field && field.startsWith('id')) {
                        field = field.substring(2);
                        if (inserted[field]) {
                          if (inserted[field].nome && inserted[field].codigo) {
                            value = `${inserted[field].codigo} - ${inserted[field].nome}`
                          } else if (inserted[field].nome && !inserted[field].codigo) {
                            value = inserted[field].nome
                          } else if (!inserted[field].nome && inserted[field].codigo) {
                            value = inserted[field].codigo
                          }
                        }
                      } else if(child) {
                        const childValue = inserted[child.field] ? inserted[child.field] : null;
                        if(childValue && Array.isArray(childValue)) {
                          value = childValue[0].codigo;
                        } else if(childValue) {
                          value = childValue[child.fieldChild];
                        } else {
                          value = ''
                        }
                      }
                      
                      return (
                        <tr key={label}>
                          <td><span>{label}:</span></td>
                          <td><p>{value}</p></td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </UploadedContent>
            </div>
          ))}
          <br />
          <br />
          <Button onClick={handleFinish}>Finalizar</Button>
          
          </>
        )}
        
      </FormContent>
    </FormWrapper>
  );
}

export default Import;

function openModal(arg0: { type: string; title: string; children: () => JSX.Element; }) {
  throw new Error('Function not implemented.');
}
