import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useHistory, RouteComponentProps } from 'react-router-dom'
import parseDate from 'date-fns/parse';
import ptBR from 'date-fns/locale/pt-BR';
import { formatDateHors, formatPhoneNumber, treeify, formatCNPJ, formatDate, formatMoney, formatGroupBy, onlyNumberStr } from '../../utils/format';
import {
  Button,
  Container,
  DivItems,
  FiltersContainer,
  LogoFooter,
  Main,
  RightButtons,
  Row,
} from './styles';
import LogoDevari from '../../assets/images/logo.png'
import ReactToPrint from 'react-to-print'
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';
import { FaPlusSquare, FaMinusSquare } from 'react-icons/fa';
import { DivRow } from './styles';
import { getProviders, getStockExtract } from '../../services/providers';
import { getClasseAndChilds } from '../../services/classes';
import { useFormModal } from '../../contexts/formModal';
import FormTemplate from '../../components/Template/forms';
import Toast from '../../components/Toast';
import useFetch from '../../hooks/useFetch';
import { getResources } from '../../services/resource';
import { getLicenseInfo } from '../../services/license';
import { isValidDate } from '../../utils/date';
import ResumeFiltersReport from '../../components/ResumeFiltersReport';
import { getBaixa, getItemsAndTitulos } from '../../services/pedidos';
interface IReport {
  Cidade?: ICidade;
  Classe?: IClasse;
  UF?: IUf;
  atualizadoEm?: string;
  bairro?: string;
  cep?: string;
  codigo?: string;
  criadoEm?: string;
  endereco?: string;
  id?: string;
  inscricaoEstadual?: string;
  nome?: string;
  nomeFantasia?: string;
  numeroEndereco?: string;
  telefone?: string;
  celular?: string;
}

interface ICidade {
  id?: string;
  nome?: string;
}

interface IClasse {
  id?: string;
  nome?: string;
}

interface IUf {
  id?: string;
  nome?: string;
  codigo?: string;
}

interface IField {
  label: string;
  field: string;
  orderName?: string;
}

interface Props extends RouteComponentProps {
  title: string;
  endpoint: string;
  filters: any;
  extraction?: any;
}

const ORDER_FIELD_PROVIDERS: IField[] = [
  { label: 'Chave', field: 'chave' },
  { label: 'Data', field: 'data' },
  { label: 'Hora', field: 'hora' },
  { label: 'Ch Doc Item', field: 'chDocItem' },
  { label: 'Classe', field: 'Classe' },
  { label: 'Documento', field: 'Documento' },
  { label: 'Classe Operação', field: 'ClasseOperacao' },
  { label: 'Pessoa', field: 'Pessoa' },
  { label: 'Recurso', field: 'Recurso' },
  { label: 'Q Ent', field: 'qEnt' },
  { label: 'Q Sai', field: 'qSai' },
  { label: 'Q Saldo', field: 'qSaldo' },
  { label: 'Vr Ent (R$)', field: 'vrEnt' },
  { label: 'Vr Sai (R$)', field: 'vrSai' },
  { label: 'Vr Saldo (R$)', field: 'vrSaldo' },
  { label: 'Custo Médio (R$)', field: 'prMedio' },
]

const StockExtractReport: React.FC<Props> = ({ title, endpoint, filters }) => {

  const location = useLocation<any>();
  const history = useHistory<any>();
  const { openModal, closeModal } = useFormModal();
  const componentRef = useRef(null);
  const [reports, setReports] = useState<Array<IReport> | any>(null);
  const [marcaTexto, setMarcaTexto] = useState(false);
  const [gerarCabecalho, setGerarCabecalho] = useState(false);
  const [openList, setOpenList] = useState<Array<string>>([]);
  const [initSaldo, setInitSaldo] = useState(false);
  const [hasGroup, setHasGroup] = useState(false);
  const [license, setLicense] = useState<any>(null);
  const [filterLabel, setFilterLabel] = useState<any>([])
  const [nivelArvore, setNivelArvore] = useState<number | null>(null);
  const [fields, setFields] = useState<IField[]>(ORDER_FIELD_PROVIDERS);

  const date = new Date();

  useEffect(() => {
    getLicense();
  }, []);

  useEffect(() => {
    if (location.state) {
      handleReportFilters();
    }
  }, []);

  function handleReportFilters() {
    const details = location.state.filtersToGet
      const _filterLabels = location.state.filtersLabels
      if (_filterLabels) {
        setFilterLabel(_filterLabels)
      }

      if (details.marcaTexto) {
        setMarcaTexto(true)
        delete details.marcaTexto
      }
      if (details.gerarCabecalho) {
        setGerarCabecalho(true)
        delete details.gerarCabecalho
      }

      if (details.Agrupamento && details.Agrupamento.length) {
        setHasGroup(true);

        if(details.exibeSaldoInicialSemMov) { //se true vai buscar o saldo total inicial
          setInitSaldo(true);
          getReports(details, true, details.exibeSaldoInicialSemMov);
        } else {
          getReports(details, true);
        }

      } else {
        
        if(details.exibeSaldoInicialSemMov) { //se true vai buscar o saldo total inicial
          setInitSaldo(true);
          getReports(details, false, details.exibeSaldoInicialSemMov);
        } else {
          getReports(details, false);
        }

      }

      const toRemove: any = [];
      if (details.Agrupamento && details.Agrupamento.length > 0) {
        for (const _agrupamento of details.Agrupamento) toRemove.push(_agrupamento);
      }
      if (!details.comCusto) toRemove.push('prMedio');
      if (!details.exibeSaldoInicial && !details.exibeSaldoInicialSemMov) {
        toRemove.push('qSaldo');
        toRemove.push('vrSaldo');
      }
      if (details.opcaoExtrato) {
        if (!details.opcaoExtrato.includes('ENT')) {
          toRemove.push('qEnt');
          toRemove.push('vrEnt');
        }
        if (!details.opcaoExtrato.includes('SAI')) {
          toRemove.push('qSai');
          toRemove.push('vrSai');
        }
      } else {
        toRemove.push('qEnt');
        toRemove.push('vrEnt');
        toRemove.push('qSai');
        toRemove.push('vrSai');
      }

      const filteredFields = ORDER_FIELD_PROVIDERS.filter((field) => !toRemove.includes(field.field));
      setFields(filteredFields);

      if (details.nivelEmArvore) setNivelArvore(details.nivelEmArvore);
      // set
  }

  async function getLicense() {
    try {
      const result = await getLicenseInfo();
      setLicense(result);
    } catch (error: any) {
      Toast.show(error, 'error');
    }
  }

  async function getReports(details: any, grouped:boolean = false, initSaldo:boolean = false) {
    let result, initResult;

    try {
      if(initSaldo) {
        initResult = await getStockExtract(details, initSaldo);
      }
      result = await getStockExtract(details);
      if(grouped) {
        let _group;
        
        if(initSaldo) {
          _group = [...initResult, ...result];
        } else {
          _group = result;
        }
        const formated = formatGroupBy(_group);
        setReports(formated)
      } else {
        if(initSaldo) {
          setReports([...initResult, ...result]);
        } else {
          setReports(result);
        }
      }

    } catch (error) {
      console.error('Erro: ', error)
    }
  }

  const pageStyle = `
  @media print{
    html, body{
      border-collapse: collapse;
      height: initial !important;
      overflow: initial !important;
      print-color-adjust: exact;
      -webkit-print-color-adjust: exact;
    }
    table, th, td {
      border-collapse: collapse;
      white-space: normal;
    }
    .page-break {
      margin-top: 20px !important;
      display: block;
      page-break-before: auto;
    }

    div:nth-child(2) {
      height: auto;
    }
  }
  @page{
    margin: 20mm;
    size: auto;
  }
  `

  async function handleSubmitModal() {
    handleReportFilters();
    closeModal();
  }

  function checkIsOpen(item: any) {
    const _find = openList.find((op: any) => {
      if (op === item) {
        return true;
      }
    });

    if (_find === null) {
      return true
    }

    return _find;
  }

  function handleOpen(item: any) {
    const _nome = item;
    const indexOf = openList.findIndex((it: any) => it === item);

    if (indexOf > -1) {
      const newList = [...openList];
      newList.splice(indexOf, 1);
      setOpenList(newList);
    } else {
      const newList = [...openList, _nome];
      setOpenList(newList);
    }
  }
  
  function _renderClassTr(item: any, identifier: number = 0, margin: number = 0): any {

    if (Array.isArray(item)) {
      return renderItemTable(item);
    } else {
      const _trs = item ? Object.keys(item) : [];

      return _trs.map((tr, index) => {
        const isOpen = nivelArvore && nivelArvore <= margin ? true : checkIsOpen(`${tr}${identifier}`) ;
        return <>
          <tr className='button'>
            <td>
              <div onClick={() => handleOpen(`${tr}${identifier}`)} className='titleContainer'>
                <div className='plusBox' style={{ marginLeft: (margin) * 10 }}>
                  {isOpen ? <FaMinusSquare color='white' size={14} /> : <FaPlusSquare color='white' size={14} />}
                </div>
                <h4>{tr === 'null' ? ` ` : tr}</h4>
              </div>
            </td>
            {fields.map((_item, index) => index !== 0 && (
              <td key={index}></td>
            ))}
          </tr>
          {isOpen ? _renderClassTr(item[tr], index, 1 + margin) : null}
        </>
      })
    }
  }

  function handleOpenModal(params: {
    classe: string;
    title: string;
    endpoint: string;
    item: any;
    filters: any,
  }) {
    openModal({
      type: 'confirm',
      title: `Editar ${params.classe}`,
      children: () => <FormTemplate
        filters={{
          classe: params.filters.classe,
        }}
        customFields={{}}
        title={params.title}
        endpoint={params.endpoint}
        onSubmitCallback={async () => await handleSubmitModal()}
        modalItem={{
          id: params.item,
        }}
        getById />,
    })
  }

  async function loadPedido(chcriacao: number, baixaAutomatica?: boolean, baixaNaoAutomatica?: boolean) {
    try {
      const pedidos = await getBaixa(chcriacao, null);
      if (pedidos.length > 0) {
        const pedido = pedidos[0];
        const itemsTitulos = await getItemsAndTitulos([chcriacao]);
        const itemToSend = {
          ...pedido,
          items: itemsTitulos.items,
          titulos: itemsTitulos.titulos,
        };
        history.push({
          pathname: `/pedidos-de-entrada/resultado/editar/${itemToSend.id ? itemToSend.id : itemToSend.chcriacao}`,
          state: {
            item: itemToSend,
            baixaAutomatica,
            baixa: baixaNaoAutomatica,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  function openRecurso(id: string) {
    handleOpenModal({
      classe: 'recurso',
      endpoint: 'recursos',
      item: id,
      title: 'recurso',
      filters: {
        classe: 'Recursos',
      }
    });
  }

  function openPessoa(id: string) {
    handleOpenModal({
      classe: 'pessoa',
      endpoint: 'entidades',
      item: id,
      title: 'pessoa',
      filters: {
        classe: 'Pessoas',
      }
    });
  }

  function renderItemTable (item: any) {
    // verifica se o item possui mais de um array dentro e transforma em um unico array.
    const _listReport = hasGroup ? item.length > 1 ? item.map((_it:any) => { return {..._it[0]}}) : item[0] : item;
    return _listReport && _listReport.map((it:any, idx:number) => {
      const ultimoSaldoRow = it.ultimo;

      return <tr 
      key={idx}
      className={marcaTexto ? 'yellow' : ''} >
        {fields.map(field => {
          const value = valueMask(field, it);
          if (field.field === 'vrEnt' || field.field === 'vrSai' || field.field === 'vrSaldo' || field.field === 'prMedio') {
            const value_formated = value ? parseFloat(value).toFixed(2) : '';
            return (<td className={value_formated[0] && value_formated[0] === '-' ? 'money red-color-cell' : 'money'}>{formatMoney(value_formated).replace('R$', '')}</td>)
          } else if (field.field === 'qEnt' || field.field === 'qSai' || field.field === 'qSaldo') {
            return (<td className={value && String(value) && String(value)[0] === '-' ? 'number-alignment red-color-cell' : 'number-alignment'}>{value}</td>)
          }

          if(field.field === 'Recurso') {
            if(it[field.field]) {
              if (it['recursoExcluido']) {
                return <td>{it[field.field]}</td>
              }
              return <td style={{
                color: 'blue',
                cursor: 'pointer'
              }}
              onClick={() => openRecurso(it['idRecurso']['$oid'])}>
                {it[field.field]}
              </td>
            }
          }

          if(field.field === 'Pessoa') {
            if(it[field.field]) {
              if (it['pessoaExcluida']) {
                return <td>{it[field.field]}</td>
              }
              return <td style={{
                color: 'blue',
                cursor: 'pointer'
              }}
              onClick={() => openPessoa(it['idPessoa']['$oid'])}>
                {it[field.field]}
              </td>
            }
          }

          if(field.field === 'chave' || field.field === 'Documento') {
            if(it[field.field]) {
              return <td style={{
                color: 'blue',
                cursor: 'pointer'
              }}
              onClick={() => loadPedido(it['chPedido'], it["baixaAutom"], !it["baixaAutom"])}>
                {it[field.field]}
              </td>
            }
          }
          
          if(ultimoSaldoRow && field.field === "data") {
            return <td>SALDO INICIAL</td>
          } else if (ultimoSaldoRow && field.field !== "Recurso") {
            return <td></td>
          }

          if(field.field === 'chDocItem') {
            return (<td className={'number-alignment'}>{value}</td>)
          }

          return (
            <td className={value && String(value) && String(value)[0] === '-' ? 'red-color-cell' : ''}>{value ?? ''}</td>
          )
        }
        )}
      </tr>
    })
  }

  function valueMask(field: any, it: any) {
    if(field.field === "Documento") {
      if(it.TipoDoc === 'NF-e' && it.NumeroDoc) {
        const rawValue = typeof it.NumeroDoc === "string" ? onlyNumberStr(it.NumeroDoc) : '';
        if(rawValue) {
          const completedString = ("000000000"+rawValue).slice(-9);
          const maskValue = completedString.replace(/\D/g, "")
          .replace(/(\d{3})(\d)/, "$1.$2")
          .replace(/(\d{3})(\d)/, "$1.$2")
          .replace(/(-\d{2})\d+?$/, "$1");
          return `${it.TipoDoc} ${it.SerieDoc} ${maskValue}`;
        } else {
          return rawValue;
        }
      } else if(it.NumeroDoc) {
        const rawValue = typeof it.NumeroDoc === "string" ? onlyNumberStr(it.NumeroDoc) : '';
        return `${it.TipoDoc} ${it.SerieDoc} ${rawValue}`;
      } else {
        return it.Documento;
      }
    } else {
      return it[field.field];
    }
  }

  function renderTable(listReports: any) {
    if(reports) {
      if (hasGroup) {
        return _renderClassTr(listReports);
      }
      return renderItemTable(listReports);
    } 
    return null
  }

  function handleOpenResumo() {
    const copyFilters = [
      {
        label: 'Caminho',
        value: 'Estoque \\ Relatórios \\ Extrato',
      },
      ...filterLabel,
    ];
    openModal({
      title: `Resumo Variáveis`,
      children: () => <ResumeFiltersReport title={title} filters={copyFilters} />
    })
  }

  return (
    <Main>
      <Row>
        <Button onClick={() => history.goBack()}>Voltar</Button>
        <RightButtons>
          <Button onClick={handleOpenResumo}>Resumo</Button>
          <ReactToPrint
            trigger={() => {
              return <Button type='button' className='buttonPrint'>Imprimir</Button>
            }}
            content={() => componentRef.current}
            documentTitle="new document"
            pageStyle={pageStyle}
          />
        </RightButtons>
      </Row>

      <Container ref={componentRef}>
        <DivRow>
          {license && (
            <Row>
              {license.nomeFantasia}
            </Row>
          )}
          <Row>
            <p>{title}</p>
            <label>{formatDateHors(date)}</label>
          </Row>
          {gerarCabecalho && <FiltersContainer>
            <p className='title'>Filtros: </p>
            <p>
              {filterLabel.map((filter: any, index: any) => {
                let value = '';

                if (Array.isArray(filter.value)) {
                  if (filter.value.length === 0) value = 'Nenhum';
                  value = filter.value.map((it: any) => `${it} `);
                } else {
                  if (filter.value instanceof Date) {
                    value = formatDate(filter.value);
                  } else {
                    value = filter.value;
                  }
                }
                return (
                  <label className='label'>
                    {filter.label} - <span className='span'>{value}</span>
                  </label>
                )
              })}
            </p>
          </FiltersContainer>}
        </DivRow>

        <DivItems>
          <table>
            <tr style={{ textAlign: 'left' }}>
              {fields.map((field) => <th
              key={field.field}>
                {field.label}
              </th>
              )}
            </tr>
            {renderTable(reports)}
          </table>
        </DivItems>

        <LogoFooter>
          <img src={LogoDevari} alt="Logo Devari" />
        </LogoFooter>
      </Container>
    </Main>
  )
}

export default StockExtractReport;
