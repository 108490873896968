/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useState, useEffect} from 'react';
import { Container, Row, Col } from 'react-grid-system';
import {
  Wrapper,
  Title,
  Content,
  HeaderContent,
  ActionsContainer,
  Button
} from './styles';
import {useHistory, useLocation} from 'react-router-dom';
import addDays from 'date-fns/addDays';
import formatDate from 'date-fns/format';
import TextInput from '../../components/TextInput';
import LookupInput from '../../components/LookupInput';
import DateInput from '../../components/DateInput';
import Table from '../../components/Table';
import Toast from '../../components/Toast';
import CardTable from '../../components/CardTable';
import {calcularPedido, criarPedido, getChavePedido, checkChavesParaBaixa, updatePedido, checkIfPedidoHasBaixa, getTitulosCondicao} from '../../services/pedidos';
import { convertToDate } from '../../utils/date';
import { detalhesData, itemCols, tituloCols } from './fixedData';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays'

import { useAuth } from '../../contexts/auth';
import { formatMoney } from '../../utils/format';

interface iSalesOrder {
  baixaAutom?: boolean;
}

const SalesOrder: React.FC<iSalesOrder> = ({baixaAutom}) => {
  const history = useHistory();
  const location: any = useLocation();
  const baixaAutomatica = baixaAutom ? baixaAutom : location.state && location.state.baixaAutomatica && location.state.baixaAutomatica === true;
  const baixaNaoAutomatica = location.state && location.state.baixa && location.state.baixa !== null;
  const baixaPedido = location.state && location.state.baixa ? location.state.baixa : null;
  const itemEdicao = location.state && location.state.item ? location.state.item : null;
  const {user} = useAuth();
  const novoPedido = location.state && location.state.item ? false : true;
  const [aprovador, setAprovador] = useState<any>(user ? user.nome ? user.nome : user.email ? user.email : user.id : null);
  const [payload, setPayload] = useState<any>({
    emissao: new Date().toISOString(),
    observacao: null,
    frete: null,
    seguro: null,
    acrescimo: null,
    desconto: null,
    idAcessorio1: null,
  });
  const [faturamento, setFaturamento] = useState({
    movimentacao: new Date().toISOString(),
    observacaoFiscal: '',
    numero: '',
    serie: '',
  });
  const [loadings, setLoadings] = useState({
    calcular: false,
    salvar: false,
  });
  const [titulosFixedValue, setTitulosFixedValue] = useState<any>({});
  const [titulosFixedValues, setTitulosFixedValues] = useState<any>({});
  const [comAprovador, setComAprovador] = useState(false);
  const [chaveCriacao, setChaveCriacao] = useState(0);
  const [observacaoToShow, setObservacaoToShow] = useState('');
  const [lookups, setLookups] = useState<any>({});
  const [initialLookups, setInitialLookups] = useState<any>({
    Representante: null,
    LocEscrituracao: null,
    Estabelecimento: null,
    Pessoa: null,
  });
  const [changedCondicaoPagamento, setChangedCondicaoPagamento] = useState(false);
  const [initialItems, setInitialItems] = useState<any>(null);
  const [initialTitulos, setInitialTitulos] = useState<any>(null);
  const [items, setItems] = useState<any>(null);
  const [titulos, setTitulos] = useState<any>(null);
  const [colunasTitulos, setColunasTitulos] = useState<any>(tituloCols());
  const [colunasItens, setColunasItens] = useState<any>(itemCols());
  const [totais, setTotais] = useState<any>({
    acrescimoItemTotal: 0,
    descontoItemTotal: 0,
    ipiTotal: 0,
    valorTotal: 0,
    valorCorrigidoTotal: 0,
    vrItemTotal: 0,
    items: [],
    titulos: [],
    condicoesPagamento: [],
  });

  useEffect(() => {
    if (lookups.CondicaoPagamento && lookups.CondicaoPagamento.value) {
      if (!changedCondicaoPagamento) {
        setChangedCondicaoPagamento(true);
      }
    }
  }, [lookups]);

  useEffect(() => {
    if (!baixaAutomatica && !baixaNaoAutomatica && !itemEdicao && user) {
      if (user.Estab) {
        setInitialLookups((oldData: any) => {
          return {
            ...oldData,
            Estabelecimento: {byId: user.Estab.id},
          }
        });
      }
      if (user.LocEscrit) {
        setInitialLookups((oldData: any) => {
          return {
            ...oldData,
            LocEscrituracao: {byId: user.LocEscrit.id},
          }
        });
      }
    }
  }, [user, baixaNaoAutomatica, baixaNaoAutomatica, itemEdicao]);

  useEffect(() => {
    if (baixaNaoAutomatica || baixaAutomatica) return;
    const timeOutId = setTimeout(() => setObservacaoToShow(payload.observacao), 500);
    return () => clearTimeout(timeOutId);
  }, [payload.observacao]);

  useEffect(() => {
    if (baixaPedido) {
      loadInitialState(baixaPedido, true);
    }
  }, [baixaPedido]);

  useEffect(() => {
    if (itemEdicao) {
      loadInitialState(itemEdicao);
    }
  }, [itemEdicao]);

  useEffect(() => {
    if (novoPedido) {
      getChaveCriacao();
    }
  }, [novoPedido]);

  useEffect(() => {
    handleAprovacao();
  }, [comAprovador]);

  useEffect(() => {
    if (totais.condicoesPagamento.length > 0 && changedCondicaoPagamento) {
      loadCondicoesCalc(totais.condicoesPagamento);
    }
  }, [totais]);

  function loadCondicoesCalc(condicoes: any[]) {
    const initialTitulosData: any[] = [];
    for (const titulo of condicoes) {
      let tituloInitialState: any = {
        Carteira: titulo.Carteira ? titulo.Carteira : null,
        TipoDocumento: titulo.TipoDocumento ? titulo.TipoDocumento : null,
        juros: titulo.juros ? parseFloat(titulo.juros) : null,
        juroPercm: titulo.juroPercm ? titulo.juroPercm : null,
        multa: titulo.multa ? parseFloat(titulo.multa) : null,
        multaPerc: titulo.multaPerc ? titulo.multaPerc : null,
        acrescimo: titulo.acrescimo ? parseFloat(titulo.acrescimo) : null,
        desconto: titulo.desconto ? parseFloat(titulo.desconto) : null,
        correcao: titulo.correcao ? new Date(titulo.correcao).toISOString() : null,
        prazo: titulo.hasOwnProperty('prazo') ? parseInt(titulo.prazo) : 0,
        vencimento: titulo.vencimento ? new Date(titulo.vencimento).toISOString() : new Date().toISOString(),
      };
      initialTitulosData.push(tituloInitialState);
    }
    setInitialTitulos((oldData: any) => {
      return initialTitulosData;
    });
    setChangedCondicaoPagamento(false);
  }

  function loadInitialState(pedido: any, baixa: boolean = false) {
    if (!baixa) {
      setChaveCriacao(pedido.chcriacao);
      if (pedido.idAprovador) {
        setComAprovador(true);
        setAprovador(
          pedido.Aprovador
            ? pedido.Aprovador.nome
              ? pedido.Aprovador.nome
              : pedido.Aprovador.codigo
              ? pedido.Aprovador.codigo
              : "Pedido aprovado"
            : "Pedido aprovado"
        );
      }
    }
    const obs = pedido.items && pedido.items.length > 0 && pedido.items[0].observacao ?
      pedido.observacao : '';
    const chacessonf = pedido.items && pedido.items.length > 0 && pedido.items[0].chacessonf ?
      pedido.chacessonf : '';
    setPayload({
      emissao: new Date(pedido.emissao).toISOString(),
      observacao: obs,
      frete: pedido.frete ? pedido.frete : null,
      seguro: pedido.seguro ? pedido.seguro : null,
      acrescimo: pedido.acrescimo ? pedido.acrescimo : null,
      desconto: pedido.desconto ? pedido.desconto : null,
      idAcessorio1: pedido.idAcessorio1 ? pedido.idAcessorio1 : null,
    });
    // setChacessonf(chacessonf);
    setInitialLookups({
      Representante: pedido.Representante
        ? { byId: pedido.Representante.id }
        : null,
      LocEscrituracao: pedido.LocEscritu
        ? { byId: pedido.LocEscritu.id }
        : null,
      Estabelecimento: pedido.Estabelecimento
        ? { byId: pedido.Estabelecimento.id }
        : null,
      Pessoa: pedido.Pessoa ? { byId: pedido.Pessoa.id } : null,
    });
    const tempInitialLookups: any = {
      Representante: pedido.Representante
        ? { byId: pedido.Representante.id }
        : null,
      LocEscrituracao: pedido.LocEscritu
        ? { byId: pedido.LocEscritu.id }
        : null,
      Estabelecimento: pedido.Estabelecimento
        ? { byId: pedido.Estabelecimento.id }
        : null,
      Pessoa: pedido.Pessoa ? { byId: pedido.Pessoa.id } : null,
    };

    const tempFaturamento: any = {};
    const tempTotais: any = {
      acrescimoItemTotal: 0,
      descontoItemTotal: 0,
      ipiTotal: 0,
      valorTotal: 0,
      valorCorrigidoTotal: 0,
      vrItemTotal: 0,
      items: [],
      titulos: [],
      condicoesPagamento: [],
    };
    const initialItemsData = [];
    const initialTitulosData = [];
    for (const item of pedido.items) {
      tempTotais.vrItemTotal += item.valor;
      tempTotais.valorTotal += item.total;
      tempTotais.items.push({
        vrItem: item.valor,
        valorTotal: item.total,
      });
      tempInitialLookups.TipoDoc = item.TipoDoc
        ? { byId: item.TipoDoc.id }
        : null;
      tempInitialLookups.TipoDeFrete = item.TipoDeFrete
        ? { byId: item.TipoDeFrete.id }
        : null;
      tempInitialLookups.Transportadora = item.Transportadora
        ? { byId: item.Transportadora.id }
        : null;
      tempInitialLookups.CondicaoPagamento = item.CondicaoPag
        ? { byId: item.CondicaoPag.id }
        : null;
      tempFaturamento.movimentacao = new Date(item.movimentacao).toISOString();
      tempFaturamento.serie = item.serie;
      tempFaturamento.observacaoFiscal = item.observacaoFiscal;
      tempFaturamento.numero = item.numero;
      let itemInitialState: any = {
        Classe: item.Classe ? item.Classe : null,
        Recurso: item.Recurso ? item.Recurso : null,
        Nucleo: item.Nucleo ? item.Nucleo : null,
        CFOP: item.CFOP ? item.CFOP : null,
        Lote: item.Lote ? item.Lote : null,
        quantidade: item.quantidade ? parseInt(item.quantidade) : null,
        unitario: item.unitario ? parseFloat(item.unitario) : null,
        acrescimoItem: item.acrescimoItem
          ? parseFloat(item.acrescimoItem)
          : null,
        descontoItem: item.descontoItem ? parseFloat(item.descontoItem) : null,
        ipi: item.ipi ? parseFloat(item.ipi) : null,
      };
      if (baixa) {
        itemInitialState = {
          ...itemInitialState,
          chpedbaixa: item.chpedbaixa ? item.chpedbaixa : null,
        };
      } else {
        itemInitialState = {
          ...itemInitialState,
          chave: item.chave ? item.chave : null,
        };
      }
      initialItemsData.push(itemInitialState);
    }

    setInitialLookups(tempInitialLookups);
    setFaturamento(tempFaturamento);
    setTotais(tempTotais);

    for (const titulo of pedido.titulos) {
      let prazo = 0;
      if (titulo.correcao) {
        prazo = differenceInCalendarDays(
          new Date(titulo.correcao),
          new Date(pedido.emissao)
        );
      } else {
        prazo = differenceInCalendarDays(
          new Date(titulo.vencimento),
          new Date(pedido.emissao)
        );
      }
      let tituloInitialState: any = {
        Carteira: titulo.Carteira ? titulo.Carteira : null,
        TipoDocumento: titulo.TipoDocumento ? titulo.TipoDocumento : null,
        juros: titulo.juros ? parseFloat(titulo.juros) : null,
        juroPercm: titulo.juroPercm ? titulo.juroPercm : null,
        multa: titulo.multa ? parseFloat(titulo.multa) : null,
        multaPerc: titulo.multaPerc ? titulo.multaPerc : null,
        acrescimo: titulo.acrescimo ? parseFloat(titulo.acrescimo) : null,
        desconto: titulo.desconto ? parseFloat(titulo.desconto) : null,
        correcao: titulo.correcao
          ? new Date(titulo.correcao).toISOString()
          : null,
        prazo,
      };

      if (!baixa) {
        tituloInitialState = {
          ...tituloInitialState,
          chave: titulo.chave ? titulo.chave : null,
        };
      }
      initialTitulosData.push(tituloInitialState);
    }
    setInitialItems(initialItemsData);
    setInitialTitulos(initialTitulosData);
  }

  function handleAprovacao() {
    if (comAprovador) {
      setColunasItens((oldValue: any) => oldValue.map((item: any) => {
        return {...item, editavel: false};
      }));
      setColunasTitulos((oldValue: any) => oldValue.map((item: any) => {
        return {...item, editavel: false};
      }));
    } else {
      setColunasItens(itemCols());
      setColunasTitulos(tituloCols());
    }
  }

  async function getChaveCriacao() {
    try {
      const result = await getChavePedido(user?.id);
      setChaveCriacao(result.chave);
    } catch (_err) {
      return Toast.show('Ocorreu um erro ao tentar buscar a chave de criação', 'error');
    }
  }

  function handleChangeLookup(field: string, options: any) {
    return setLookups((prevState: any) => {
      return {...prevState, [field]: options}
    });
  }

  function handleChangePayload(field: string, value: any) {
    return setPayload((prevState: any) => {
      return {...prevState, [field]: value}
    });
  }

  function handleChangeFaturamentoPayload(field: string, value: any) {
    return setFaturamento((prevState: any) => {
      return {...prevState, [field]: value}
    });
  }

  function validatePayload(): boolean {
    if (!payload.emissao) {
      Toast.show('Insira a data de emissão');
      return false;
    }
    if (baixaAutomatica || baixaNaoAutomatica) {
      if (!lookups.TipoDoc || !lookups.TipoDoc.value) {
        Toast.show('Insira o tipo de documento do faturamento');
        return false;
      }
      if (!faturamento.movimentacao) {
        Toast.show('Insira a data de movimentação do faturamento');
        return false;
      } else if (!faturamento.serie && lookups.TipoDoc.item && lookups.TipoDoc.item.codigo && lookups.TipoDoc.item.codigo.includes('NF')) {
        Toast.show('Insira a série do faturamento');
        return false;
      } else if (!faturamento.numero) {
        Toast.show('Insira o número do faturamento');
        return false;
      }
    }

    if (!lookups.LocEscrituracao || !lookups.LocEscrituracao.value) {
      Toast.show('Insira o local de escrituração');
      return false;
    } else if (!lookups.Estabelecimento || !lookups.Estabelecimento.value) {
      Toast.show('Insira o estabelecimento');
      return false;
    } else if (!lookups.Pessoa || !lookups.Pessoa.value) {
      Toast.show('Insira a pessoa');
      return false;
    }
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (!item.idRecurso) {
        Toast.show(`Insira o recurso do item ${ i + 1 }`);
        return false;
      } else if (!item.idClasse) {
        Toast.show(`Insira a classe do item ${ i + 1 }`);
        return false;
      } else if (!item.idNucleo) {
        Toast.show(`Insira o núcleo do item ${ i + 1 }`);
        return false;
      } else if (!item.quantidade) {
        Toast.show(`Insira a quantidade do item ${ i + 1 }`);
        return false;
      } else if (!item.unitario) {
        Toast.show(`Insira o preço do item ${ i + 1 }`);
        return false;
      }
    }

    return true;
  }

  function validarTitulos(): boolean {
    for (let i = 0; i < titulos.length; i++) {
      const titulo = titulos[i];

      if (!titulo.hasOwnProperty('idTipoDocumento')) {
        Toast.show(`Insira o tipo de documento do título ${ i + 1 }`);
        return false;
      } else if (!titulo.hasOwnProperty('prazo')) {
        Toast.show(`Insira o prazo do título ${ i + 1 }`);
        return false;
      } else if (!titulo.hasOwnProperty('vencimento')) {
        Toast.show(`Insira o vencimento do título ${ i + 1 }`);
        return false;
      }
    }

    return true;
  }

  function handleChangeLoading(field: string, value: boolean) {
    return setLoadings(oldValue => {
      return {...oldValue, [field]: value};
    });
  }

  async function handleGetCondicao() {
    try {
      const result = await getTitulosCondicao({
        condicaoPagamento: lookups.CondicaoPagamento.value,
      });
      setTotais((oldValue: any) => {
        return {...oldValue, condicoesPagamento: result.condicoesPagamento}
      });
      return result.condicoesPagamento;
    } catch (err: any) {
      return false;
    }
  }

  async function handleCalcular(): Promise<boolean> {
    handleChangeLoading('calcular', true);

    if (validatePayload()) {
      let titles = titulos;
      if (lookups.CondicaoPagamento && lookups.CondicaoPagamento.value) {
        titles = await handleGetCondicao();
      }

      const toCalcPayload = {
        ...payload,
        items,
        titulos: titles,
      };
      try {
        const result = await calcularPedido(toCalcPayload);
        Object.keys(result).map((key: string) => {
          if (key !== "items" && key !== "titulos") {
            result[key] = parseFloat(result[key]);
          }
          return key;
        });
        result.items = result.items.map((item: any) => {
          return {
            ...item,
            valorTotal: parseFloat(item.valorTotal),
            vrItem: parseFloat(item.vrItem),
          }
        })
        result.titulos = result.titulos.map((item: any) => {
          return {
            ...item,
            valorCorrigido: parseFloat(item.valorCorrigido),
            valorPrincipal: parseFloat(item.valorPrincipal),
          }
        })
        setTotais((oldValue: any) => {
          const toUpdateTotais = {...oldValue, ...result};
          calcTitulosFixedValues(titles, toUpdateTotais);
          calcTitulosFixedValue(titles, toUpdateTotais);
          return toUpdateTotais;
        });
        
        handleChangeLoading('calcular', false);
      } catch (err: any) {
        Toast.show(err.message);
        handleChangeLoading('calcular', false);
        return false;
      }
    } else {
      handleChangeLoading('calcular', false);
      return false;
    }

    handleChangeLoading('calcular', false);
    return true;
  }

  async function handleSalvar() {
    handleChangeLoading('salvar', true);
    try {
      let toSendPayload: any = {
        tipo: 'pedido de venda',
        baixaAutomatica,
        baixaId: user?.id,
        idLocEscritu: lookups.LocEscrituracao.value,
        idEstabelecimento: lookups.Estabelecimento.value,
        idPessoa: lookups.Pessoa.value,
        idRepresentante: lookups.Representante.value,
        idAprovador: null,
        aprovacao: null,
        observacao: payload.observacao,
        emissao: convertToDate(payload.emissao),
        frete: payload.frete ? payload.frete : 0.00,
        seguro: payload.seguro ? payload.seguro : 0.00,
        acrescimo: payload.acrescimo ? payload.acrescimo : 0.00,
        desconto: payload.desconto ? payload.desconto : 0.00,
        idAcessorio1: payload.idAcessorio1 ? payload.idAcessorio1 : '',
        ItensDoPedido: items.map((_item: any, index: number) => {
          let item = { ..._item, chcriacao: chaveCriacao };
          if (baixaAutomatica) {
            item = {...item, chpedbaixa: item.chave};
          }
          if (totais.items && totais.items.length > 0) {
            const {vrItem = null, valorTotal = null, ...itemPedido} = item;
            return {
              ...itemPedido,
              total: totais.items[index].valorTotal,
              valor: totais.items[index].vrItem,
            }
          } else {
            return item;
          }
        }),
        Titulos: titulos.map((_titulo: any, index: number) => {
          const titulo = { ..._titulo, chpedido: chaveCriacao, idClasse: '61cd2739ad47e0a02eab67fe', };
          return titulo;
        }),
      };

      if (comAprovador) {
        toSendPayload = {
          ...toSendPayload,
          idAprovador: user?.id,
          aprovacao: new Date(),
        };
      }

      if (baixaAutomatica) {
        const {movimentacao, ...faturamentoData} = faturamento;
        toSendPayload = {
          ...toSendPayload,
        idTipoDoc: lookups.TipoDoc.value,
        idTransportadora: lookups.Transportadora ? lookups.Transportadora.value : null,
        movimentacao: convertToDate(movimentacao),
        ...faturamentoData,
        }
      }

      await criarPedido(toSendPayload);
      handleChangeLoading('salvar', false);
      if (baixaAutomatica) {
        Toast.show(`A baixa de chave ${chaveCriacao} foi criado com sucesso.`, 'success');
      } else {
        Toast.show(`O pedido de chave ${chaveCriacao} foi criado com sucesso.`, 'success');
      }
      history.push('/pedidos-de-saida');
    } catch (error: any) {
      handleChangeLoading('salvar', false);
      Toast.show(error.message);
    }
  }

  async function handleSalvarBaixaNaoAutomatica() {
    handleChangeLoading('salvar', true);
    try {
      const {movimentacao, ...faturamentoData} = faturamento;
      let toSendPayload: any = {
        tipo: 'baixa de venda',
        baixaAutomatica: false,
        baixa: true,
        baixaId: user?.id,
        idLocEscritu: lookups.LocEscrituracao.value,
        idEstabelecimento: lookups.Estabelecimento.value,
        idPessoa: lookups.Pessoa.value,
        idRepresentante: lookups.Representante.value,
        idTipoDoc: lookups.TipoDoc.value,
        idTransportadora: lookups.Transportadora ? lookups.Transportadora.value : null,
        ...faturamentoData,
        observacao: payload.observacao,
        emissao: convertToDate(payload.emissao),
        movimentacao: convertToDate(movimentacao),
        frete: payload.frete ? payload.frete : 0.00,
        seguro: payload.seguro ? payload.seguro : 0.00,
        acrescimo: payload.acrescimo ? payload.acrescimo : 0.00,
        desconto: payload.desconto ? payload.desconto : 0.00,
        idAcessorio1: payload.idAcessorio1 ? payload.idAcessorio1 : '',
        ItensDoPedido: items.map((_item: any, index: number) => {
          let item = { ..._item, chcriacao: chaveCriacao };
          if (totais.items && totais.items.length > 0) {
            const {vrItem = null, valorTotal = null, ...itemPedido} = item;
            return {
              ...itemPedido,
              total: totais.items[index].valorTotal,
              valor: totais.items[index].vrItem,
            }
          } else {
            return item;
          }
        }),
        Titulos: titulos.map((_titulo: any, index: number) => {
          const titulo = { ..._titulo, chpedido: chaveCriacao, idClasse: '61cd2739ad47e0a02eab67fe', };
          return titulo;
        }),
      };

      await criarPedido(toSendPayload);
      handleChangeLoading('salvar', false);
      Toast.show(`A baixa de chave ${chaveCriacao} foi criado com sucesso.`, 'success');
      history.push('/pedidos-de-saida');
    } catch (error: any) {
      handleChangeLoading('salvar', false);
      Toast.show(error.message);
    }
  }

  async function handleSalvarUpdate(){
    try {
      let toSendPayload: any = {
        tipo: 'baixa de venda',
        baixaAutomatica: false,
        baixa: true,
        baixaId: user?.id,
        idAprovador: null,
        aprovacao: null,
        idLocEscritu: lookups.LocEscrituracao.value,
        idEstabelecimento: lookups.Estabelecimento.value,
        idPessoa: lookups.Pessoa.value,
        idRepresentante: lookups.Representante.value,
        observacao: payload.observacao,
        emissao: convertToDate(payload.emissao),
        frete: payload.frete ? payload.frete : 0.00,
        seguro: payload.seguro ? payload.seguro : 0.00,
        acrescimo: payload.acrescimo ? payload.acrescimo : 0.00,
        desconto: payload.desconto ? payload.desconto : 0.00,
        idAcessorio1: payload.idAcessorio1 ? payload.idAcessorio1 : '',
        ItensDoPedido: items.map((_item: any, index: number) => {
          let item = { ..._item, chcriacao: chaveCriacao };
          if (baixaAutomatica) {
            item = {...item, chpedbaixa: item.chave};
          }
          if (totais.items && totais.items.length > 0) {
            const {vrItem = null, valorTotal = null, ...itemPedido} = item;
            return {
              ...itemPedido,
              total: totais.items[index].valorTotal,
              valor: totais.items[index].vrItem,
            }
          } else {
            return item;
          }
        }),
        Titulos: titulos.map((_titulo: any, index: number) => {
          const titulo = { ..._titulo, chpedido: chaveCriacao, idClasse: '61cd2739ad47e0a02eab67fe', };
          return titulo;
        }),
      };

      if (comAprovador) {
        toSendPayload = {
          ...toSendPayload,
          idAprovador: user?.id,
          aprovacao: new Date(),
        };
      }

      await updatePedido(chaveCriacao, toSendPayload);
      handleChangeLoading('salvar', false);
      Toast.show(`O pedido de chave ${chaveCriacao} foi atualizado com sucesso.`, 'success');
      history.push('/pedidos-de-saida');
    } catch (error: any) {
      handleChangeLoading('salvar', false);
      Toast.show(error.message);
    }
  }

  async function checarQuantidadeItems(): Promise<boolean> {
    try {
      const chaves = items.map((item: any) => item.chpedbaixa);
      const result = await checkChavesParaBaixa(chaves);
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        const baixas = result[i];

        if (baixas.faltaBaixar === 0) {
          Toast.show(`O item de chave ${item.chave} já foi baixado`, 'error');
          return false;
        }

        if (item.quantidade > baixas.faltaBaixar) {
          Toast.show(`A baixa do item de chave ${item.chave} só pode ter a quantidade menor ou igual a ${baixas.faltaBaixar}`, 'error');
          return false;
        }
      }
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  }

  async function checarBaixasItens(): Promise<boolean> {
    try {
      const chaves = items.map((item: any) => item.chave);
      const result = await checkIfPedidoHasBaixa(chaves);
      if (result.hasBaixa === false) {
        return true;
      }
      Toast.show('Este pedido tem baixas e não pode ser desaprovado');
      return false;
    } catch (err) {
      console.log(err);
      return false;
    }
  }

  async function handleSubmit(e: any) {
    let tipo, calcular, titulos, baixaOK, podeDesaprovar;
    if (e) {
      e.preventDefault();
      tipo = e.nativeEvent.submitter.name;
    }
    
    switch(tipo) {
      case 'salvar':
        calcular = await handleCalcular();
        titulos = validarTitulos();
        if (calcular && titulos) {
          if (baixaNaoAutomatica) {
            baixaOK = await checarQuantidadeItems();
            if (baixaOK) {
              await handleSalvarBaixaNaoAutomatica();
            }
          } else {
            if (itemEdicao) {
              await handleSalvarUpdate();
            } else {
              await handleSalvar();
            }
          }
        }
          break;
      case 'aprovar':
        if (comAprovador) {
          if (itemEdicao) {
            podeDesaprovar = await checarBaixasItens();
            if (podeDesaprovar) {
              setComAprovador(false);
            }
          } else {
            setComAprovador(false);
          }
        } else {
          calcular = await handleCalcular();
          titulos = validarTitulos();

          if (calcular && titulos) {
            setComAprovador(true);
          }
        }
          break;
      case 'calcular':
        await handleCalcular();
        break;
      default:
        break;
    }
  }

  function handleChangeTitulos(result: any) {
    setTitulos(result);
  }

  function calcTitulosFixedValues(titles: any = null, total: any = null) {
    let _titles = titles || titulos;
    if (!_titles || !total) return [];

    const values = [];
    for (let index = 0; index < _titles.length; index++) {
      const titulo: any = _titles[index];
      let data = {
        vencimento: '',
        valorCorrigido: total.titulos && total.titulos.length > 0 && total.titulos[index] ? total.titulos[index].valorCorrigido : 0.0,
      };
      if (titulo.prazo) {
        const emissao = convertToDate(payload.emissao);
        if (emissao) {
          const vencimento = formatDate(addDays(emissao, titulo.prazo), 'dd/MM/yyyy');
          data = {...data, vencimento};
        }
      }

      values.push(data);
    }

    return setTitulosFixedValues(values);
  }

  function calcTitulosFixedValue(titles: any = null, total: any = null) {
    let _titles = titles || titulos;
    if (total.valorTotal && _titles && _titles.length > 0) {
      const porTitulo = parseFloat(String(total.valorTotal/_titles.length));
      setTitulosFixedValue({
        valorPrincipal: porTitulo,
      });
    } else {
      setTitulosFixedValue({
        valorPrincipal: 0.00
      });
    }
  }

  function moneyFormat(value: any) {
    const money = formatMoney(value).replace('R$', '');
    return money;
  }
  
  return (
    <Wrapper>
      <HeaderContent>
        <Title>{baixaNaoAutomatica ? 'Baixa do Pedido' : baixaAutomatica ? 'Baixa Automática' :  'Pedido de Saída'} - Ch. de Criação N° {chaveCriacao}</Title>
        <ActionsContainer>
          <Button form="custom-form" type="submit" id="calcular" name="calcular" disabled={loadings.calcular}>
            {loadings.calcular ? "Calculando..." : "Calcular"}
          </Button>
          {!baixaNaoAutomatica && (
            <Button form="custom-form" type="submit" id="aprovar" name="aprovar" disabled={loadings.calcular}>
              {comAprovador && "Desaprovar"}
              {!comAprovador ? loadings.calcular ? "Aprovando..." : "Aprovar" : ""}
            </Button>
          )}
          {/* <Button type="button" onClick={() => {}}>Imprimir</Button> */}
          <Button form="custom-form" type="submit" id="salvar" name="salvar" disabled={loadings.salvar}>
            {loadings.salvar ? "Salvando..." : "Salvar"}
          </Button>
          {/* <Button type="button" onClick={() => {}}>Faturar</Button> */}
        </ActionsContainer>
      </HeaderContent>
      <Content id="custom-form" onSubmit={handleSubmit}>
        <Container fluid style={{ width: "100%" }}>
        <Row>
          <Col md={7}>
            <Row>
              <h1>Cabeçalho</h1>
            </Row>
            <Row>
                <Col md={baixaNaoAutomatica ? 6 : 4}>
                  <DateInput
                    disabled={comAprovador}
                    label="Emissão" 
                    id="emissao" 
                    name="Emissão" 
                    value={payload.emissao}
                    onChange={({target: {value}}) => handleChangePayload('emissao', value)}
                    withHour={false}
                  />
                </Col>
                <Col md={baixaNaoAutomatica ? 6 : 4}>
                  <LookupInput
                    disabled={comAprovador}
                    initialState={initialLookups.Representante}
                    label="Vendedor"
                    endpoint="entidades"
                    field="nome"
                    filters={{
                      classe: "Vendedores",
                    }}
                    multi={false}
                    onChange={(options) => handleChangeLookup("Representante", options)}
                  />
                </Col>
                {!baixaNaoAutomatica && (
                  <Col md={4}>
                    <TextInput 
                      label="Aprovador"
                      id="aprovador"
                      disabled
                      fixed
                      value={comAprovador ? aprovador ? aprovador : 'Pedido não aprovado' : 'Pedido não aprovado'}
                    />
                  </Col>
                )}
              </Row>
              <Row>
                <Col md={6}>
                 <LookupInput
                    disabled={comAprovador}
                    label="Local de Escrituração"
                    initialState={initialLookups.LocEscrituracao}
                    endpoint="entidades"
                    field="nome"
                    filters={{
                      classe: 'Local de Escrituração'
                    }}
                    multi={false}
                    onChange={(options) => handleChangeLookup("LocEscrituracao", options)}
                  />
                </Col>
                <Col md={6}>
                  <LookupInput
                    disabled={comAprovador}
                    label="Estabelecimento"
                    initialState={initialLookups.Estabelecimento}
                    endpoint="entidades"
                    field="nome"
                    filters={{
                      classe: 'Estabelecimentos'
                    }}
                    multi={false}
                    onChange={(options) => handleChangeLookup("Estabelecimento", options)}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <LookupInput
                    disabled={comAprovador}
                    label="Pessoa"
                    endpoint="entidades"
                    initialState={initialLookups.Pessoa}
                    field="nome"
                    filters={{
                      classe: 'Pessoas'
                    }}
                    multi={false}
                    onChange={(options) => handleChangeLookup("Pessoa", options)}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <LookupInput
                    disabled={comAprovador}
                    initialState={initialLookups.CondicaoPagamento}
                    label="Condição de Pagamento"
                    endpoint="tabela"
                    field="nome"
                    filters={{
                      classe: 'Condições de Pagamento'
                    }}
                    multi={false}
                    onChange={(options) => handleChangeLookup("CondicaoPagamento", options)}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextInput 
                    disabled={comAprovador}
                    label="Observação"
                    id="observacao"
                    value={payload.observacao}
                    onChange={({target: {value}}) => handleChangePayload('observacao', value)}
                  />
                </Col>
              </Row>
            </Col>
          <Col md={5}>
            {(baixaNaoAutomatica || baixaAutomatica) ? (
              <>
                <Row>
                  <Col>
                    <h1>Faturamento</h1>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <DateInput
                      disabled={comAprovador}
                      label="Data de Movimentação" 
                      id="movimentacao"
                      name="Data de Movimentação" 
                      value={faturamento.movimentacao}
                      onChange={({target: {value}}) => handleChangeFaturamentoPayload('movimentacao', value)}
                      withHour={true}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={8}>
                    <LookupInput
                      disabled={comAprovador}
                      label="Tipo de Documento"
                      endpoint="tabela"
                      field="nome"
                      filters={{
                        classe: 'Tipos de Documento'
                      }}
                      multi={false}
                      onChange={(options) => handleChangeLookup("TipoDoc", options)}
                    />
                  </Col>
                  <Col md={4}>
                    <TextInput 
                      disabled={comAprovador}
                      label="Série"
                      id="serie"
                      onChange={({target: {value}}) => handleChangeFaturamentoPayload('serie', value)}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <TextInput 
                      disabled={comAprovador}
                      label="Número"
                      id="numero"
                      onChange={({target: {value}}) => handleChangeFaturamentoPayload('numero', value)}
                    />
                  </Col>
                  <Col md={6}>
                    <LookupInput 
                      disabled={comAprovador}
                      label="Transportadora"
                      endpoint="entidades"
                      field="nome"
                      filters={{
                        classe: 'Transportadoras'
                      }}
                      multi={false}
                      onChange={(options) => handleChangeLookup("Transportadora", options)}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                  <TextInput 
                      disabled={comAprovador}
                      label="Observação p/ Nota Fiscal"
                      id="observacaoFiscal"
                      onChange={({target: {value}}) => handleChangeFaturamentoPayload('observacaoFiscal', value)}
                    />
                  </Col>
                </Row>
              </>
            ) : (
              <CardTable 
                title="Detalhes"
                data={detalhesData(payload, lookups, observacaoToShow)}
              />
            )}
          </Col>
        </Row>
        <Row style={{marginTop: 32}}>
          <Col md={2.5}>
            <TextInput 
              label="Frete"
              id="frete"
              masked 
              money
              disabled={comAprovador}
              onChange={({target: {value}}) => handleChangePayload('frete', value)}
            />
          </Col>
          <Col md={2.5}>
            <TextInput 
              label="Seguro"
              id="seguro"
              masked 
              money
              disabled={comAprovador}
              onChange={({target: {value}}) => handleChangePayload('seguro', value)}
            />
          </Col>
          <Col md={2.5}>
            <TextInput 
              label="Acréscimo"
              id="acrescimo"
              masked 
              money
              disabled={comAprovador}
              onChange={({target: {value}}) => handleChangePayload('acrescimo', value)}
            />
          </Col>
          <Col md={2.5}>
           <TextInput 
              label="Desconto"
              id="desconto"
              masked 
              money
              disabled={comAprovador}
              onChange={({target: {value}}) => handleChangePayload('desconto', value)}
            />
          </Col>
          <Col md={2}>
            <TextInput 
              label="Acessório"
              id="acessorio"
              disabled={comAprovador}
              onChange={({target: {value}}) => handleChangePayload('idAcessorio1', value)}
            />
          </Col>
        </Row>
        <Table 
          actions={['insert', 'delete']}
          title="Itens"
          onChange={(result: any) => setItems(result)}
          fromForm
          withTotal
          isRecurso
          initialValue={initialItems}
          disabled={comAprovador}
          chaveCriacao={chaveCriacao}
          lookupsRegras={lookups}
          type="Rendas"
          fixedValues={totais.items}
          total={[
            {
              field: "vrItem",
              value: moneyFormat(totais.vrItemTotal),
            },
            {
              field: "acrescimoItem",
              value: moneyFormat(totais.acrescimoItemTotal),
            },
            {
              field: "descontoItem",
              value: moneyFormat(totais.descontoItemTotal),
            },
            {
              field: "ipi",
              value: moneyFormat(totais.ipiTotal),
            },
            {
              field: "valorTotal",
              value: moneyFormat(totais.valorTotal),
            },
          ]}
          cols={colunasItens}
        />
        <Table
          title="Títulos" 
          actions={['insert', 'delete']}
          fromForm
          withTotal
          isRecurso
          isTitulo
          initialValue={initialTitulos}
          disabled={comAprovador}
          fixedValues={titulosFixedValues}
          fixedValue={titulosFixedValue}
          total={[
            {
              field: "valorPrincipal",
              value: moneyFormat(totais.valorTotal),
            },
            {
              field: "valorCorrigido",
              value: moneyFormat(totais.valorCorrigidoTotal),
            },
          ]}
          cols={colunasTitulos}
          onChange={handleChangeTitulos}
        />
        </Container>
      </Content>
    </Wrapper>
  );
}

export default SalesOrder;