import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useHistory, RouteComponentProps } from 'react-router-dom'
import formatDateFns from 'date-fns/format';
import ptBr from 'date-fns/locale/pt-BR';
import { formatDateHors, formatDate, formatGroupBy, formatMoney, onlyNumberStr, nfeMask } from '../../utils/format';
import {
  Button,
  Container,
  DivItems,
  FiltersContainer,
  LogoFooter,
  Main,
  RightButtons,
  Row,
} from './styles';
import LogoDevari from '../../assets/images/logo.png'
import ReactToPrint from 'react-to-print'
import { FaPlusSquare, FaMinusSquare } from 'react-icons/fa';
import { DivRow } from './styles';
import { useFormModal } from '../../contexts/formModal';
import FormTemplate from '../../components/Template/forms';
import Toast from '../../components/Toast';
import { getLicenseInfo } from '../../services/license';
import { getBaixa, getItemsAndTitulos, getPurchaseReport } from '../../services/pedidos';
import { isValid } from 'date-fns';
import ResumeFiltersReport from '../../components/ResumeFiltersReport';
import { BASE_URL } from '../../environment/stores';
import { useAuth } from '../../contexts/auth';
import { addDays } from 'date-fns/esm';
interface IReport {
  Cidade?: ICidade;
  Classe?: IClasse;
  UF?: IUf;
  atualizadoEm?: string;
  bairro?: string;
  cep?: string;
  codigo?: string;
  criadoEm?: string;
  endereco?: string;
  id?: string;
  inscricaoEstadual?: string;
  nome?: string;
  nomeFantasia?: string;
  numeroEndereco?: string;
  telefone?: string;
  celular?: string;
}

interface ICidade {
  id?: string;
  nome?: string;
}

interface IClasse {
  id?: string;
  nome?: string;
}

interface IUf {
  id?: string;
  nome?: string;
  codigo?: string;
}

interface IField {
  label: string;
  field: string;
  orderName?: string;
  right?: boolean;
}

interface Props extends RouteComponentProps {
  title: string;
  endpoint: string;
  filters: any;
  extraction?: any;
}

const ORDER_FIELD_PROVIDERS: IField[] = [
  { label: '', field: 'tipo' },
  { label: 'Chave', field: 'chcriacao' },
  { label: 'Item', field: 'chave' },
  { label: 'Chave', field: 'chbaixa' },
  { label: 'Item', field: 'chpedbaixa' },
  { label: 'LE', field: 'LE' },
  { label: 'DOC', field: 'numero' },
  { label: 'Ch Acesso NFe', field: 'chacessonf'},
  { label: 'Emissão', field: 'emissao' },
  { label: 'Entrada', field: 'criadoEm' },
  { label: 'CFOP', field: 'cfop'},
  { label: 'Classe', field: 'Classe' },
  { label: 'Recurso', field: 'Recurso'},
  { label: 'UM', field: 'UnidadeMedida'},
  { label: 'Pessoa', field: 'Pessoa' },
  { label: 'Núcleo', field: 'Nucleo' },
  { label: 'Qtd', field: 'qtd', right: true},
  { label: 'Unit (R$)', field: 'unitario', right: true},
  { label: 'Total (R$)', field: 'total', right: true},
]

const PurchaseReport: React.FC<Props> = ({ title, endpoint, filters }) => {
  const { user } = useAuth();
  const location = useLocation<any>();
  const history = useHistory<any>();
  const { openModal, closeModal } = useFormModal();
  const componentRef = useRef(null);
  const [reports, setReports] = useState<Array<IReport> | any>(null);
  const [marcaTexto, setMarcaTexto] = useState(false);
  const [gerarCabecalho, setGerarCabecalho] = useState(false);
  const [openList, setOpenList] = useState<Array<string>>([]);
  const [hasGroup, setHasGroup] = useState(false);
  const [consolidada, setConsolidada] = useState(false);
  const [fields, setFields] = useState<IField[]>(ORDER_FIELD_PROVIDERS)
  const [license, setLicense] = useState<any>(null);
  const [filterLabel, setFilterLabel] = useState<any>([])
  const [nivelArvore, setNivelArvore] = useState<number | null>(null);

  const date = new Date();

  useEffect(() => {
    getLicense();
  }, []);

  useEffect(() => {
    if (location.state) {
      handleReportFilters();
    }
  }, []);

  function handleReportFilters() {
    const details = location.state.filtersToGet
    const _filterLabels = location.state.filtersLabels
    
    if (_filterLabels) {
      setFilterLabel(_filterLabels);
      let _newFields = [...fields];

      if (details.idApresentacao === 'CONSOLIDADA') {
        setConsolidada(true);
        const toRemove = ['chave', 'chbaixa', 'chpedbaixa', 'Recurso', 'UnidadeMedida', 'Nucleo', 'qtd', 'unitario'];
        _newFields = _newFields.filter(it => !toRemove.includes(it.field));
      }

      if(!_filterLabels.find((it:any) => (it.label === "Exibe CFOP" && it.value === 'SIM'))) {
        _newFields = _newFields.filter(it => it.field !== "cfop");
      }

      if(_filterLabels.find((it:any) => (it.label === "Chave Acesso NF" && it.value === "NÃO"))) {
        _newFields = _newFields.filter(it => it.field !== "chacessonf")
      }

      setFields(_newFields)
    }
    
    if (details.marcaTexto) {
      setMarcaTexto(true)
      delete details.marcaTexto
    }
    if (details.gerarCabecalho) {
      setGerarCabecalho(true)
      delete details.gerarCabecalho
    }
    if (details.Agrupamento && details.Agrupamento.length) {
      setHasGroup(true);
      getReports(details, true);
      removeFields(details.Agrupamento);
    } else {
      getReports(details);
    }
    if(details.nivelArvore) {
      setNivelArvore(details.nivelArvore);
    }
  }

  function removeFields(agrupamento:string[]) {
    let newFields = fields;
    
    agrupamento.map((ag) => {
      if(ag === "Recurso (Nome)" || ag === "Recurso (Código+Nome)" || ag === "Recurso" || ag === 'Recurso (Código)') {
        newFields = newFields.filter(field => field.label !== "Recurso");
      } else if(ag === 'Número do Documento') {
        newFields = newFields.filter(field => field.label !== "DOC");
      } else if(ag === 'Local de Escrituração') {
        newFields = newFields.filter(field => field.label !== "LE");
      } else {
        newFields = newFields.filter(field => field.label !== ag);
      }
    });

    setFields(newFields);
  }

  async function getLicense() {
    try {
      const result = await getLicenseInfo();
      setLicense(result);
    } catch (error: any) {
      Toast.show(error, 'error');
    }
  }

  async function getReports(details: any, grouped:boolean = false) {
    let result;

    try {
      result = await getPurchaseReport(details);
      if(grouped) {
        const formated = formatGroupBy(result);
        setReports(formated);
      } else {
        setReports(result);
      }

    } catch (error) {
      console.error('Erro: ', error)
    }
  }

  const pageStyle = `
  @media print{
    html, body{
      border-collapse: collapse;
      height: initial !important;
      overflow: initial !important;
      print-color-adjust: exact;
      -webkit-print-color-adjust: exact;
    }
    table, th, td {
      border-collapse: collapse;
      white-space: normal;
    }
    .page-break {
      margin-top: 20px !important;
      display: block;
      page-break-before: auto;
    }

    div:nth-child(2) {
      height: auto;
    }

    .filter-resume {
      display: block;
    }
  }
  @page{
    margin: 20mm;
    size: auto;
  }
  `

  async function handleSubmitModal() {
    handleReportFilters();
    closeModal();
  }

  function handleOpenModal(params: {
    classe: string;
    title: string;
    endpoint: string;
    item: any;
    filters: any,
  }) {
    openModal({
      type: 'confirm',
      title: `Editar ${params.classe}`,
      children: () => <FormTemplate
        filters={{
          classe: params.filters.classe,
        }}
        customFields={{}}
        title={params.title}
        endpoint={params.endpoint}
        onSubmitCallback={async () => await handleSubmitModal()}
        modalItem={{
          id: params.item,
        }}
        getById />,
    })
  }

  function checkIsOpen(item: any) {
    const _find = openList.find((op: any) => {
      if (op === item) {
        return true;
      }
    });

    if (_find === null) {
      return true
    }

    return _find;
  }

  function handleOpen(item: any) {
    const _nome = item;
    const indexOf = openList.findIndex((it: any) => it === item);

    if (indexOf > -1) {
      const newList = [...openList];
      newList.splice(indexOf, 1);
      setOpenList(newList);
    } else {
      const newList = [...openList, _nome];
      setOpenList(newList);
    }
  }

  async function loadPedido(chcriacao: number, baixaAutomatica?: boolean, baixaNaoAutomatica?: boolean) {
    try {
      const pedidos = await getBaixa(chcriacao, null);
      if (pedidos.length > 0) {
        const pedido = pedidos[0];
        const itemsTitulos = await getItemsAndTitulos([chcriacao]);
        const itemToSend = {
          ...pedido,
          items: itemsTitulos.items,
          titulos: itemsTitulos.titulos,
        };
        history.push({
          pathname: `/pedidos-de-entrada/resultado/editar/${itemToSend.id ? itemToSend.id : itemToSend.chcriacao}`,
          state: {
            item: itemToSend,
            baixaAutomatica,
            baixa: baixaNaoAutomatica,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  }
  
  function _renderClassTr(item: any, identifier: number = 0, margin: number = 0): any {

    if (Array.isArray(item)) {
      return renderItemTable(item);
    } else {
      const _trs = Object.keys(item);

      return _trs.map((tr, index) => {
        const isOpen = nivelArvore && nivelArvore <= margin ? true : checkIsOpen(`${tr}${identifier}`) ;
        return <>
          <tr className='button'>
            <td>
              <div onClick={() => handleOpen(`${tr}${identifier}`)} className='titleContainer'>
                <div className='plusBox' style={{ marginLeft: (margin) * 10 }}>
                  {isOpen ? <FaMinusSquare color='white' size={14} /> : <FaPlusSquare color='white' size={14} />}
                </div>
                <h4>{tr === 'null' ? ` ` : tr}</h4>
              </div>
            </td>
            {fields.map((_item, index) => index !== 0 && (
              <td key={index}></td>
            ))}
          </tr>
          {isOpen ? _renderClassTr(item[tr], index, 1 + margin) : null}
        </>
      })
    }
  }

  function renderItemTable (item: any) {
    const _listReport = hasGroup ? item[0] : item;
    return _listReport.map((it:any, idx:number) => {
      return <tr 
      key={idx}
      className={marcaTexto ? 'yellow' : ''}>
        {fields.map(field => {
          let value = it[field.field];
          if((field.field === 'qtd' || field.field === "unitario" || field.field === "total") && value) {
            value = formatMoney(value).replace("R$", "");
          }

          if(field.field === 'chcriacao') {
            if(it[field.field]) {
              return <td style={{
                color: 'blue',
                cursor: 'pointer'
              }}
              onClick={() => loadPedido(it[field.field])}>
                {it[field.field]}
              </td>
            }
          }

          if(field.field === 'chbaixa') {
            if(it[field.field]) {
              return <td style={{
                color: 'blue',
                cursor: 'pointer'
              }}
              onClick={() => loadPedido(it[field.field], it["tipo"] === "Baixa automática", it["tipo"] === "Baixa não automática")}>
                {it[field.field]}
              </td>
            }
          }

          if (field.field === 'tipo') {
            return <td>
              <div style={{
                width: 15,
                height: 15,
                maxHeight: 15,
                maxWidth: 15, 
                fontSize: 10,
                textAlign: 'center',
                color: 'white',
                backgroundColor: (it["tipo"] === "Baixa automática" || it["tipo"] === "Baixa não automática") ? "blue" : it["tipo"] === "Aprovado" ? "green" : "red"
                }}>{(it["tipo"] === "Baixa automática" || it["tipo"] === "Baixa não automática") ? "PB" : it["tipo"] === "Aprovado" ? "PA" : "NA"}</div>
            </td>
          }

          
          if(field.field === 'numero') {
            if (it["tipo"] !== "Baixa automática" && it["tipo"] !== "Baixa não automática") {
              value = formatMoney(it["chcriacao"]).substring(2);
              value = value.slice(0, -3);
              return <td>PED {value}</td>
            }
            if(value && (it["TipoDocumento"] === "NFC-e" || it["TipoDocumento"] === "NF-e")) {
              value = onlyNumberStr(value);
              value = nfeMask(value);
              return <td>
               {it["TipoDocumento"]} {value}
              </td>
            } else if (value && (it["TipoDocumento"] === "PED")) {
              value = onlyNumberStr(value);
              value = formatMoney(value).substring(2);
              value = value.slice(0, -3);
              return (<td>{it["TipoDocumento"]} {value}</td>);
            } else if (it["TipoDocumento"] && value){
              return <td>
               {it["TipoDocumento"]} {value}
              </td>
            }
          }

          if(field.field === 'Recurso') {
            if(it[field.field]) {
              if (it['recursoExcluido']) {
                return <td>{it[field.field]}</td>
              }
              return <td style={{
                color: 'blue',
                cursor: 'pointer'
              }}
              onClick={() => openRecurso(it['idRecurso'])}>
                {it[field.field]}
              </td>
            }
          }

          if(field.field === 'Pessoa') {
            if(it[field.field]) {
              if (it['pessoaExcluida']) {
                return <td>{it[field.field]}</td>
              }
              return <td style={{
                color: 'blue',
                cursor: 'pointer'
              }}
              onClick={() => openPessoa(it['idPessoa'])}>
                {it[field.field]}
              </td>
            }
          }

          if(field.field === 'chacessonf') {
            if(it[field.field]) {
              return <td style={{
                color: 'blue',
                cursor: 'pointer'
              }}
              onClick={() => {
                const url = `${BASE_URL}/pedidos/danfe?chacessonf=${it[field.field]}&token=${user?.token}&consolidada=${consolidada ? 'true' : 'false'}`
                window.open(url, '__blank')}
              }>
                {it[field.field]}
              </td>
            }
          }
          
          if(field.field === 'emissao') {
            if(it[field.field]){
              const emissao = it[field.field]
              const data = addDays(new Date(emissao), 1);
              
              if(isValid(data)){
                value = formatDateFns(data, 'dd/MM/yy eeeeee', {
                  locale: ptBr,
                });
              } else{
                value = ''
              }
            }
          }

          if(field.field === 'criadoEm') {
            if(it[field.field]){
              const criadoEm = it[field.field]
              const data = addDays(new Date(criadoEm), 1);
              
              if(isValid(data)){
                value = formatDateFns(data, 'dd/MM/yy eeeeee', {
                  locale: ptBr,
                });
              } else{
                value = ''
              }
            }
          }

          return <td style={{textAlign: field.right ? 'right' : 'left'}}>
            {value}
          </td>
        }
        )}
      </tr>
    })
  }

  function renderTable() {
    if(reports) {
      if (hasGroup) {
        return _renderClassTr(reports);
      }
      return renderItemTable(reports);
    } 
    return null
  }

  function handleOpenResumo() {
    const copyFilters = [
      {
        label: 'Caminho',
        value: 'Compras \\ Relatórios \\ Pedidos de Entrada',
      },
      ...filterLabel,
    ];
    openModal({
      title: `Resumo Variáveis`,
      children: () => <ResumeFiltersReport title={title} filters={copyFilters} />
    })
  }

  function openRecurso(id: string) {
    handleOpenModal({
      classe: 'recurso',
      endpoint: 'recursos',
      item: id,
      title: 'recurso',
      filters: {
        classe: 'Recursos',
      }
    });
  }

  function openPessoa(id: string) {
    handleOpenModal({
      classe: 'pessoa',
      endpoint: 'entidades',
      item: id,
      title: 'pessoa',
      filters: {
        classe: 'Pessoas',
      }
    });
  }

  return (
    <Main>
      <Row>
        <Button onClick={() => history.goBack()}>Voltar</Button>
        <RightButtons>
          <Button onClick={handleOpenResumo}>Resumo</Button>
          <ReactToPrint
            trigger={() => {
              return <Button type='button' className='buttonPrint'>Imprimir</Button>
            }}
            content={() => componentRef.current}
            documentTitle="new document"
            pageStyle={pageStyle}
            
          />
        </RightButtons>
      </Row>

      <Container ref={componentRef}>
        <DivRow className="filter-resume">
          <ResumeFiltersReport title={title} filters={[
              {
                label: 'Caminho',
                value: 'Compras \\ Relatórios \\ Pedidos de Entrada',
              },
              ...filterLabel,
          ]} />
        </DivRow>
        <DivRow>
          {license && (
            <Row>
              {license.nomeFantasia}
            </Row>
          )}
          <Row>
            <p>{title}</p>
            <label>{formatDateHors(date)}</label>
          </Row>
          {gerarCabecalho && (<FiltersContainer>
            <p className='title'>Filtros: </p>
            <p>
              {filterLabel.map((filter: any, index: any) => {
                let value = '';

                if (Array.isArray(filter.value)) {
                  if (filter.value.length === 0) value = 'Nenhum';
                  value = filter.value.map((it: any) => `${it} `);
                } else {
                  if (filter.value instanceof Date) {
                    value = formatDate(filter.value);
                  } else {
                    value = filter.value;
                  }
                }
                return (
                  <label className='label'>
                    {filter.label} - <span className='span'>{value}</span>
                  </label>
                )
              })}
            </p>
          </FiltersContainer>)}
        </DivRow>
        <DivItems>
          <table>
            {!consolidada && (
              <tr>
                <th></th>
                <th colSpan={2}>Pedido</th>
                <th colSpan={2}>Baixa</th>
                <th colSpan={fields.length - 5}></th>
              </tr>
            )}
            <tr style={{ textAlign: 'left' }}>
              {fields.map((field) => <th
              key={field.field}>
                {field.label}
              </th>
              )}
            </tr>
            {renderTable()}
          </table>
        </DivItems>

        <LogoFooter>
          <img src={LogoDevari} alt="Logo Devari" />
        </LogoFooter>
      </Container>
    </Main>
  )
}

export default PurchaseReport;