import Provider from "./config";

export const getResources = async (details: any) => {
  const payload: any = {
    ...details
  }
  
  if(payload.idAgrupamento){
    const agrupador = payload.idAgrupamento
    payload['agruparPor'] = agrupador
    delete payload.idAgrupamento;
  }
  if(payload.Ordem) {
    let order = '';

    payload.Ordem.map((or:any) => {
      order = `${order}${or},`;
    });
    payload['ordenarPor'] = order;
    delete payload.Ordem;
  }
  
  try {
    const result = await Provider.get('relatorios/recursos/', {
      params:  payload
    })

    return result.data
  } catch (err: any) {
    throw Error ("Ocorreu um erro ao tentar buscar os produtos.")
  }
}

export const getProvider = async (id: string) => {
  try {
    const result = await Provider.get(`entidades/${id}`)

    return result.data
  } catch (err: any) {
    throw Error ("Ocorreu um erro ao tentar buscar o fornecedor.")
  }
}

export const getResource = async (id: string) => {
  try {
    const result = await Provider.get(`recursos/${id}`)

    return result.data
  } catch (err: any) {
    throw Error ("Ocorreu um erro ao tentar buscar o recurso.")
  }
}

export const getResourcesByName = async (nome: string) => {
  try {
    const result = await Provider.get(`recursos`, {
      params: { nome }
    });
    return result.data;
  } catch (error) { 
    throw Error ("Ocorreu um erro ao tentar buscar o recurso.")
  }
}

export const createResource = async (payload: any) => {
  try {
    const result = await Provider.post(`recursos`, payload);
    return result.data;
  } catch (error) {
    throw Error ("Ocorreu um erro ao tentar criar um recurso.");
  }
}

export const getVehicles = async (params:any) => {
  try {
    const response = await Provider.get('/relatorios/veiculos', {
      params: params
    });
    return response.data;
  } catch (error) {
    throw Error("Erro ao buscar relatório veículos");
  }
}