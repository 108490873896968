import styled from 'styled-components';

import ButtonLayout from '../../components/Button';


export const Main = styled.div`
  display: flex;
  flex-direction: column;

  .buttonPrint{
    background-color: var(--primary);
    border-radius: 30px;
    color: var(--primary-complementary-text-color);
    cursor: pointer;
    font-size: 12px;
    margin-bottom: 4px;
    margin-left: auto;
    padding: 10px;
  }

  .filter-resume {
    display: none;
  }
`

export const Container = styled.div`
  background: #fff;
  height: auto;
  padding: 10px 6px;
  width: 100%;
  font-size: 12px;
`

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
`;

export const DivRow = styled.div`
  background-color: var(--primary-lighter);
  width: 100%;
  color: var(--white);
  font-size: 14px;
  font-weight: 500;
  border-radius: 2px;
  padding: 8px;
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 8px;
`;

export const SearchInput = styled.input`
  height: 40px;
  border-radius: 4px;
  margin-top: -8px;
  margin-bottom: 16px;
  padding: 0 8px;
`;

export const Title = styled.p`
  font-size: 24px;

  & span {
    font-size: 14px;
    color: var(--muted);
  }
`;

export const Content = styled.div`
  background-color: var(--white);

  border-radius: 2px;
  border-left: 1px solid var(--opacity-background);
  border-right: 1px solid var(--opacity-background);
  border-top: 1px solid var(--opacity-background);
  overflow: auto;

  position: relative;

  h1 {
    width: 100%;
    font-size: 20px;
    background-color: var(--primary);
    color: var(--white);
    font-weight: 200;
    margin-bottom: 10px;
    padding-left: 5px;
    border-radius: 5px;
  }
  h2 {
    width: 100%;
    font-size: 18px;
    background-color: var(--secondary);
    color: var(--white);
    font-weight: 200;
    margin-bottom: 10px;
    padding-left: 5px;
    border-radius: 5px;
  }
`;

export const FormWrapper = styled.div`
  width: 100%;
  height: 100%;


  & > p {
    margin-bottom: 16px;
  }
`;

export const FormContent = styled.div`
  background-color: var(--white);

  border-radius: 2px;
  border: 1px solid var(--opacity-background);
  overflow: auto;
  padding: 16px;

  position: relative;

  h1 {
    width: 100%;
    font-size: 20px;
    background-color: var(--primary);
    color: var(--white);
    font-weight: 200;
    margin-bottom: 10px;
    padding-left: 5px;
    border-radius: 5px;
  }
  h2 {
    width: 100%;
    font-size: 18px;
    background-color: var(--secondary);
    color: var(--white);
    font-weight: 200;
    margin-bottom: 10px;
    padding-left: 5px;
    border-radius: 5px;
  }
`;

export const ActionsContainer = styled.div`
  width: auto;
  display: flex;
  gap: 16px;

  & button {
    width: auto;
  }
`;

export const Button = styled(ButtonLayout)`
  color: var(--primary-complementary-text-color);
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 4px;
  padding: 4px;
  width: 8%;

  @media (max-width: 559px) {
    width: 100%;
  }
`;

export const Form = styled.form`
  & > button {
    margin-top: 32px;
    font-size: 16px;
    padding: 0px;
  }
`;

export const DivItems = styled.div`
  margin-top: 6px;
  width: 100%;
  height: 55vh;
  max-width: 100%;
  overflow: auto;

  label:before{
    margin-right: 4px;
  }

  label{
    background-color: transparent;
    display: flex;
    align-items: center;
    font-size: 14px;
  }

  span{
    color: var(--primary);
    cursor: pointer;

    &:hover{
      text-decoration: underline;
    }
  }

  svg{
    color: var(--primary);
  }

  h3{
    margin-bottom: 6px;
    text-decoration: underline;
  }

  table{
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 12px;
    white-space: nowrap;
  }

  th:first-child, td:first-child {
    max-width: 60px;
  }
  
  table, th, td {
    /* border: 1px solid var(--opacity-background) !important; */
    font-size: 12px;
    
    @media (max-width: 1194px) {
      font-size: 10px;
    }
  }

  th {
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: var(--primary);
    color: #fff;
  }
  
  .yellow:hover{
    background: #ffff0099;
  }
  
  th, td{
    padding: 4px;
  }
  
  td{
    word-break: break-word;
  }

  tr:nth-child(odd){
    background: #2dacc626;
  }

  .no-wrap{
    white-space: nowrap;
  }

  .left{
    text-align: left;
  }

  .right{
    text-align: right;
  }

  .red{
    color: red;
  }

  .titleContainer {
    display: flex;
    cursor: pointer;
    align-items: center;
    
    .plusBox {
      display: flex;
      align-items: center;
      margin-right: 6px;
      width: 16px;
    }
    h4{
      
      font-weight: 500;
    }
  }

  .button {
    td {
      white-space: nowrap;
      background-color: var(--primary-lighter);
      color: white;
    }
  }

`;

export const LogoFooter = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  img{
    margin-top: 6px;
    width: 100px;
  }
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  background-color: var(--white);

  border: 1px solid var(--opacity-background);
`;

export const UploadInput = styled.input`
  width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;

  &:focus + label {
    outline: 1px dotted #000;
	  outline: -webkit-focus-ring-color auto 5px;
  }

  & + label * {
	  pointer-events: none;
  }
`;

export const UploadLabel = styled.label`
  font-weight: 500;
  color: white;
  background-color: var(--primary);
  display: inline-block;
  padding: .5em 1em;
  border-radius: 4px;
  cursor: pointer;
  transition: .2s;

  &:hover {
    filter: brightness(.95);
  }
`;

export const UploadedContent = styled.div`
  border: 1px solid var(--opacity-background);
  padding: 8px;
  border-radius: 4px;

  & span {
    font-weight: 700;
    font-family: 'Courier New', Courier, monospace !important;
  }

  & p {
    font-family: 'Courier New', Courier, monospace !important;
  }
`;

export const UploadButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & p {
    font-size: 12px;
    font-style: italic;
    margin-bottom: 16px;
  }
`;

export const UploadedItemActions = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 4px;
`;

export const FiltersContainer = styled.div`

  .title {
    margin-top: 12px;
  }

  .label {
    font-weight: 700;
    margin-right: 6px;
  }

  .span {
    font-weight: 400;
  }
`;

export const RightButtons = styled.div`
  display: flex;

  button {
    width: fit-content;
    margin-right: 15px;
  }
`;