import { Column } from "../../../components/Table/types";

export const detalhesData = (payload: any, lookups: any, observacaoToShow: string) => {
    return [
        {
          type: 'title',
          value: payload.emissao ? 'Emissão' : '',
        },
        {
          value: payload.emissao ? payload.emissao : '',
        },
        {
          type: 'title',
          value: lookups.LocEscrituracao ? 'Local de escrituração' : ''
        },
        {
          value: lookups.LocEscrituracao 
          ? lookups.LocEscrituracao.item && lookups.LocEscrituracao.item.nome 
          ? `Nome: ${lookups.LocEscrituracao.item.nome}` : 'Nome: ---' : '',
        },
        {
          value: lookups.LocEscrituracao 
          ? lookups.LocEscrituracao.item && lookups.LocEscrituracao.item.telefone 
          ? `Fone: ${lookups.LocEscrituracao.item.telefone}` : 'Fone: ---' : ''
        },
        {
          value: lookups.LocEscrituracao 
          ? lookups.LocEscrituracao.item && lookups.LocEscrituracao.item.endereco 
          ? `Endereço: ${lookups.LocEscrituracao.item.endereco}` : 'Endereço: ---' : ''
        },
        {
          type: 'title',
          value: lookups.Estabelecimento ? 'Estabelecimento' : ''
        },
        {
          value: lookups.Estabelecimento 
          ? lookups.Estabelecimento.item && lookups.Estabelecimento.item.nome 
          ? `Nome: ${lookups.Estabelecimento.item.nome}` : 'Nome: ---' : '',
        },
        {
          value: lookups.Estabelecimento 
          ? lookups.Estabelecimento.item && lookups.Estabelecimento.item.telefone 
          ? `Fone: ${lookups.Estabelecimento.item.telefone}` : 'Fone: ---' : ''
        },
        {
          value: lookups.Estabelecimento 
          ? lookups.Estabelecimento.item && lookups.Estabelecimento.item.email 
          ? `E-mail: ${lookups.Estabelecimento.item.email}` : 'E-mail: ---' : ''
        },
        {
          type: 'title',
          value: lookups.Pessoa ? 'Pessoa' : ''
        },
        {
          value: lookups.Pessoa 
          ? lookups.Pessoa.item && lookups.Pessoa.item.nome 
          ? `Nome: ${lookups.Pessoa.item.nome}` : 'Nome: ---' : '',
        },
        {
          value: lookups.Pessoa 
          ? lookups.Pessoa.item && lookups.Pessoa.item.telefone 
          ? `Fone: ${lookups.Pessoa.item.telefone}` : 'Fone: ---' : ''
        },
        {
          value: lookups.Pessoa 
          ? lookups.Pessoa.item && lookups.Pessoa.item.email 
          ? `E-mail: ${lookups.Pessoa.item.email}` : 'E-mail: ---' : ''
        },
        {
          type: 'title',
          value: lookups.CondicaoPagamento ? 'Pagamento' : '',
        },
        {
          value: lookups.CondicaoPagamento 
          ? lookups.CondicaoPagamento.item && lookups.CondicaoPagamento.item.nome 
          ? lookups.CondicaoPagamento.item.nome : '---' : ''
        },
        {
          type: 'title',
          value: payload.observacaoToShow ? 'Observação' : '',
        },
        {
          value: observacaoToShow || '',
        },
      ];
}

export const itemCols = (): Column[] => {
    return [
        {
          campo: 'chave',
          nome: 'Chave',
          editavel: false,
          tipo: 'texto',
          fixo: true,
        },
        {
          campo: 'classeRequisicao',
          nome: 'Classe Requisição',
          editavel: true,
          tipo: 'lookup',
          multi: false,
          tabela: 'classes',
          classe: 'requisições',
          classeBase: "627a7eb2b079322564c3c232",
          fixo: true,
        },
        {
          campo: 'emissao',
          nome: 'Emissão',
          tipo: 'texto',
          editavel: true,
          tabela: 'entidades',
          mascara: 'data/hora',
          classe: 'Requisições',
          fixo: true,
        },
        {
          campo: 'centroCusto',
          nome: 'Centro de Custo',
          editavel: true,
          tipo: 'lookup',
          multi: false,
          tabela: 'entidades',
          classe: 'centros de custos',
          fixo: true,
        },
        {
          campo: 'requisitante',
          nome: 'Requisitante',
          tipo: 'lookup',
          multi: false,
          editavel: true,
          tabela: 'entidades',
          classe: 'Usuários',
          fixo: true,
        },
        {
          campo: 'Recurso',
          nome: 'Produto',
          editavel: true,
          tipo: 'lookup',
          multi: false,
          tabela: 'recursos',
        },
        {
          campo: 'Lote',
          nome: 'Lote',
          editavel: true,
          tipo: 'lookup',
          multi: false,
          tabela: 'tabela',
          classe: 'lotes',
          fixo: true,
        },
        {
          campo: 'quantidade',
          nome: 'Qtde',
          editavel: true,
          tipo: 'texto',
          mascara: 'inteiro',
          classe: 'recursos'
        },
        {
          campo: 'unitario',
          nome: 'Custo',
          editavel: false,
          tipo: 'texto',
          mascara: 'dinheiro',
          fixo: true,
        },
        {
          campo: 'observacao',
          nome: 'Observação',
          editavel: true,
          tipo: 'texto',
        },
        {
          campo: 'aprovacao',
          nome: 'Aprovação',
          editavel: false,
          tipo: 'texto',
          mascara: 'data/hora',
        },
      ];
}

export const tituloCols = (): Column[] => {
    return [
        {
          campo: 'chave',
          nome: 'Chave',
          editavel: false,
          tipo: 'texto',
          fixo: true,
        },
        {
          campo: 'chaveReq',
          nome: 'Chave Requisição',
          editavel: false,
          tipo: 'texto',
          fixo: true,
        },
        {
          campo: 'dataDaida',
          nome: 'Data Saída',
          editavel: true,
          tipo: 'texto',
          mascara: 'data/hora',
          fixo: true,
        },
        {
          campo: 'Deposito',
          nome: 'Depósito',
          editavel: true,
          tipo: 'lookup',
          multi: false,
          tabela: 'entidades',
          classe: 'depósitos',
        },
        {
          campo: 'Lote',
          nome: 'Lote',
          editavel: true,
          tipo: 'lookup',
          multi: false,
          tabela: 'tabela',
          classe: 'Lotes',
          fixo: true,
        },
        {
          campo: 'quantidade',
          nome: 'Qtde',
          editavel: true,
          tipo: 'texto',
          mascara: 'inteiro',
        },
        {
          campo: 'custo',
          nome: 'Custo',
          editavel: false,
          tipo: 'texto',
          mascara: 'dinheiro',
          fixo: true,
        },
      ];
}